import {connect} from "react-redux";
import React from "react";
import {Redirect, Route} from "react-router-dom";

const PrivateRoute = ({ component: Component, token, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return token ? (
          <Component {...props} />
        ) : (
          <Redirect to="/"/>
        )
      }}
    />
  );
}

const mapStateToProps = state => ({
  token: state.personal.token,
})

const mapDispatchToProps = dispatch => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateRoute);