export default class Validator {
  static identity(identity) {
    return /^.+@.+|\d{11}$/.test(identity);
  }

  static password(password) {
    return /^.{3,}$/.test(password);
  }

  static verifyCode(verifyCode) {
    return /^.+$/.test(verifyCode);
  }

  static comparisonPasswords(newPassword, confirmPassword) {
    return newPassword === confirmPassword;
  }
}