import React from 'react';
import {connect} from 'react-redux';
import Validator from "../../utils/validator";
import ApiService from "../../services/ApiService";
import {showMessageModal} from "../../store/actions/messageModalActions";
import {removeProfile, removeToken} from "../../store/actions/personalActions";
import {addFormError} from "../../store/actions/profileModalActions";

class SettingsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',

      formErrors: {}
    }
  }

  handleNewPasswordChange = (e) => {
    this.setState({
      newPassword: e.target.value.trim()
    })
  }

  handleConfirmPasswordChange = (e) => {
    this.setState({
      confirmPassword: e.target.value.trim()
    })
  }

  handleCurrentPasswordChange = (e) => {
    this.setState({
      currentPassword: e.target.value.trim()
    })
  }

  onChangePassword = async () => {
    this.removeAllFormErrors();

    let isSuccess = true;
    let currentPassword = this.state.currentPassword;
    let newPassword = this.state.newPassword;
    let confirmPassword = this.state.confirmPassword;

    if (!Validator.password(currentPassword)) {
      this.setFormError("currentPassword", "Введите текущий пароль");
      isSuccess = false;
    }

    if (!Validator.password(newPassword)) {
      this.setFormError("newPassword", "Новый пароль некорректный");
      isSuccess = false;
    }

    if (!Validator.comparisonPasswords(newPassword, confirmPassword)) {
      this.setFormError("confirmPassword", "Пароли не совпадают");
      isSuccess = false;
    }

    if (isSuccess) {
      await ApiService.loadBaseResource();

      const link = ApiService.baseResource.link("profile:change-password");

      if (!link) {
        this.props.addFormError("common", "Действие недоступно");

        return;
      }

      try {
        await ApiService.client.create(
          link.uri.uri, {currentPassword, newPassword});

        this.setState({
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        })

        this.props.onSuccess("Пароль был успешно измененен");

      } catch (error) {
        this.props.addFormError("common", error.response.data.error);
      }
    }
  }

  setFormError = (field, message) => {
    this.setState(prevState => {
      let formErrors = Object.assign({}, prevState.formErrors);

      formErrors[field] = message;

      return {formErrors};
    })
  }

  removeAllFormErrors = () => {
    this.setState({
      formErrors : {}
    })
  }

  render(){
    let {personal} = this.props;

    return (
      <div className="main-container__inner">
        <div className="container">
          <h1 className="main-container__header heading">Настройки</h1>

          <div className="profile">
            {personal.profile?.email &&
            <div className="profile__item profile__item--email">{personal.profile?.email}</div>
            }
            {personal.profile?.phone &&
            <div className="profile__item profile__item--phone">{personal.profile?.phone}</div>
            }
          </div>

          <form className="profile__form">
            {this.state.formErrors && Object.keys(this.state.formErrors).length > 0 ?
              (
                <p className="form__messages">
                  {Object.values(this.state.formErrors).map(item => (
                    <span key={item}>{item}<br/></span>
                  ))}
                </p>
              )
              : null}

            <div className="form__control form__control--mod-1">
              <label className="input__label--mod-1" htmlFor="currentPassword">Текущий пароль</label>
              <input className={"input input--mod-1" + (this.state.formErrors?.currentPassword ? " input--invalid" : "")}
                     type="password"
                     name="currentPassword" placeholder="Старый пароль" value={this.state.currentPassword}
                     onChange={this.handleCurrentPasswordChange} id="currentPassword"/>
            </div>

            <div className="form__control form__control--mod-1">
              <label className="input__label--mod-1" htmlFor="newPassword">Новый пароль</label>
              <input className={"input input--mod-1" + (this.state.formErrors?.currentPassword ? " input--invalid" : "")}
                     type="password"
                     name="newPassword" placeholder="Новый пароль" value={this.state.newPassword}
                     onChange={this.handleNewPasswordChange} id="newPassword"/>
            </div>

            <div className="form__control form__control--mod-1">
              <label className="input__label--mod-1" htmlFor="confirmPassword">Повторите новый пароль</label>
              <input className={"input input--mod-1" + (this.state.formErrors?.currentPassword ? " input--invalid" : "")}
                     type="password"
                     name="newPassword" placeholder="Повторите новый пароль" value={this.state.confirmPassword}
                     onChange={this.handleConfirmPasswordChange} id="confirmPassword"/>
            </div>

            <div className="form__footer">
              <button className="button button-primary button-primary--mini" type="button"
                      onClick={this.onChangePassword}>Сохранить новый пароль</button>
            </div>

          </form>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  personal: state.personal,
})

const mapDispatchToProps = dispatch => ({
  onSuccess(title) {
    dispatch(showMessageModal({title}))
  },
  onError() {
    dispatch(removeToken());
    dispatch(removeProfile());
  },
  addFormError(field, message) {
    dispatch(addFormError(field, message))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsPage);
