import React from "react";
import {connect} from "react-redux";
import Modal from "@material-ui/core/Modal";
import {hideMessageModal} from "../../store/actions/messageModalActions";

const MessageModal = ({isShown, title, text, closeModal, link}) => (
  <Modal
    className="modal modal--message"
    open={isShown}
    aria-labelledby="modal-title"
  >
    <div className="modal__content">
      <div className="modal__inner">

        <button className="modal__close" onClick={closeModal}></button>

        <h2 className="modal__header" id="modal-title">{title}</h2>

        {text &&
          <p className="modal__text">{text}</p>
        }

        <div className="modal__button">
          {link ? (
            <a className="button button-primary button-primary--mini" href={link.href} target="_blank" download>{link.title}</a>
          ) : (
            <button className="button button-primary button-primary--mini" type="button"
                    onClick={closeModal}>Подтвердить</button>
          )}
        </div>
      </div>
    </div>
  </Modal>
)

const mapStateToProps = state => ({
  isShown: state.messageModal.isShown,
  title: state.messageModal.title,
  text: state.messageModal.text,
  link: state.messageModal.link
})

const mapDispatchToProps = dispatch => ({
  closeModal() {
    dispatch(hideMessageModal())
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageModal);