import ApiService from "../../services/ApiService";
import {
  removeQuestionnaireResearch, removeQuestionnaireResearchList,
  resetValues, setLastQuestionnaireResearch,
  setQuestionnaire,
  setQuestionnaireList, setQuestionnaireResearch,
  setQuestionnaireResearchList
} from "../actions/questionnaireActions";
import {showMessageModal} from "../actions/messageModalActions";
import {startLoading, stopLoading} from "../actions/commonActions";
import {removeProfile, removeToken, setTicket} from "../actions/personalActions";
import store from "../store";
import {setOptions} from "../actions/profileModalActions";
import {IdentityType, ModalType} from "../../utils/constants";

export const fetchQuestionnaireListData =  () => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    await ApiService.loadBaseResource();

    const link = ApiService.baseResource.link("research:questionnaires");

    const response = await ApiService.client.fetchResource(link.uri.uri);

    let resources = response.prop("questionnaireResponses");

    let questionnaireList = [];

    resources.forEach(item => {
      questionnaireList.push({
        code: item.prop("code"),
        title: item.prop("title"),
        description: item.prop("description")
      })
    })

    dispatch(setQuestionnaireList(questionnaireList));
    dispatch(stopLoading());
  } catch (error) {
    console.dir(error);

    dispatch(stopLoading());
  }
}

export const fetchQuestionnaireData = (code) => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    await ApiService.loadBaseResource();

    const link = ApiService.baseResource.link("research:questionnaire");

    const response = await ApiService.client.fetchResource(link.uri.fill({questionnaireCode: code}));

    ApiService.responses["questionnaire"] = response;

    dispatch(setQuestionnaire({
      title: response.prop("title"),
      description: response.prop("description"),
      modules: response.prop("modules"),
      href: response.prop("href"),
      code: response.prop("code"),
    }));
    dispatch(stopLoading());
  } catch (error) {
    console.dir(error);

    dispatch(stopLoading());
  }
}

export const sendQuestionnaireResultData = ({appointmentWithADoctor, onlyShowResults, baseUrl = ""}) => async (dispatch, getState) => {
  dispatch(startLoading());

  let {questionnaire, values} = getState().questionnaire;

  try {
    await ApiService.loadBaseResource();

    const clinicId = getState()?.personal?.clinicId;

    const response = await ApiService.client.create(
      ApiService.responses["questionnaire"].uri.fetchedURI + (clinicId ? '?clinicId=' + clinicId : ""), values[questionnaire.code]);

    dispatch(setTicket(response.prop("results")?.[0]?.ticket));
    dispatch(resetValues(questionnaire.code));

    const token = getState()?.personal?.token;

    let results = response.prop("results");

    results?.forEach((item, index, array) => {
      array[index].downloadLink = response.link("research:download").prop("href");
    })

    dispatch(setQuestionnaireResearch(results));

    let link = appointmentWithADoctor ? "/screening-result/appointment-with-a-doctor" : "/screening-result";

    if (token?.access_token || onlyShowResults) {
      window.location.hash = baseUrl + link;
    } else {
      dispatch(setOptions({
        modalType: ModalType.REGISTRATION,
        registrationTitle: null,
        isShown: true,
        identityType: IdentityType.EMAIL,
        text: "Зарегистрироваться и сохранить исследование в личном кабинете",
        isOnlyOneAction: true,
        isNotChooseIdentity: false,
        mainButtonText: "Зарегистрироваться и сохранить",
        redirectLink: link,
        additionalButton: {
          to: link,
          text: "Посмотреть результат без регистрации",
          onClick: () => {
            if (appointmentWithADoctor) {
              window?.ym(85118782,'reachGoal','no_registration');
            }
          }
        },
        isEmailSend: false,
        isDownloadResearch: false
      }));
    }

    dispatch(stopLoading());
  } catch (error) {
    console.dir(error);

    dispatch(stopLoading());
  }
}

export const fetchQuestionnaireResearchListData = () => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    await ApiService.loadBaseResource();

    const link = ApiService.baseResource.link("profile:researches");

    if (!link) {
      handleError(dispatch);

      throw new Error();
    }

    const response = await ApiService.client.fetchResource(link.uri.uri);

    let resources = response.prop("researchResponses");

    let questionnaireResearchList = [];

    const token = getState()?.personal?.token;

    resources.forEach(item => {
      questionnaireResearchList.push({
        id: item.prop("id"),
        code: item.prop("code"),
        name: item.prop("name"),
        date: item.prop("date"),
        self: item.uri.resourceURI,
        downloadLink: item.link("research:download").prop("href") + "?access_token=" + token?.access_token
      })
    })

    dispatch(setQuestionnaireResearchList(questionnaireResearchList));
    dispatch(stopLoading());
  } catch (error) {
    console.dir(error);

    dispatch(stopLoading());
  }
}

export const fetchQuestionnaireResultData = (id) => async (dispatch, getState) => {
  dispatch(startLoading());

  let {questionnaireResearchList} = getState().questionnaire;

  try {
    await ApiService.loadBaseResource();

    if (!questionnaireResearchList) {
      await fetchQuestionnaireResearchListData()(dispatch, getState)

      questionnaireResearchList = getState().questionnaire.questionnaireResearchList;
    }

    const response = await ApiService.client.fetchResource(questionnaireResearchList.find(item => (id === item.id))?.self);

    dispatch(setQuestionnaireResearch(response.prop("objectNodes")));
    await fetchQuestionnaireResearchListData()(dispatch, getState);

    dispatch(stopLoading());
  } catch (error) {
    console.dir(error);

    dispatch(stopLoading());
  }
}

export const fetchLastQuestionnaireResultData = () => async (dispatch, getState) => {
  dispatch(startLoading());

  try {
    await ApiService.loadBaseResource();

    const link = ApiService.baseResource.link("profile:latest-cardiovascular-diseases");

    if (!link) {
      handleError(dispatch);

      throw new Error();
    }

    const response = await ApiService.client.fetchResource(link.uri.uri);

    dispatch(setLastQuestionnaireResearch(response.prop("objectNodes")));

    dispatch(stopLoading());
  } catch (error) {
    console.dir(error);

    dispatch(stopLoading());
  }
}

function handleError(dispatch) {
  store?.dispatch(removeToken());
  store?.dispatch(removeProfile());
  store?.dispatch(removeQuestionnaireResearch());
  store?.dispatch(removeQuestionnaireResearchList());
  window.location.hash = "/";
  store?.dispatch(showMessageModal("Вам не доступно данное действие", "Попробуйте, авторизоваться заново или обратитесь в техподдержку"));

}
