import React from "react";
import {bindActionCreators} from "redux";
import {
  fetchLastQuestionnaireResultData,
  fetchQuestionnaireResearchListData
} from "../../store/actionCreators/questionnaireActionCreator";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {formatDateTime} from "../../utils/renderHelpers";
import {RiskScale} from "../RiskScale/RiskScale";
import {ObjectiveData} from "../ObjectiveData/ObjectiveData";
import {InfluenceOfFactors} from "../InfluenceOfFactors/InfluenceOfFactors";

class Personal extends React.Component {
  componentDidMount() {
    this.props.fetchQuestionnaireResearchList();
    this.props.fetchLastQuestionnaireResearch();
  }

  render() {
    const questionnaireResearchList = this.props.questionnaireResearchList;
    const lastQuestionnaireResearch = this.props.lastQuestionnaireResearch;

    return (
      <div className="main-container__inner">
        {lastQuestionnaireResearch && (
          <RiskScale result={lastQuestionnaireResearch[0]} />
        )}

        {lastQuestionnaireResearch && (
        <div className="main-result__influence-of-risk influence-of-risk result-section">
          <div className="container">
            <h2 className="influence-of-risk__heading result-section__heading">Степень влияния факторов риска на
              развитие сердечно-сосудистых заболеваний</h2>

            <InfluenceOfFactors result={lastQuestionnaireResearch[0]}/>

          </div>
        </div>
        )}

        {lastQuestionnaireResearch?.[0]?.objectiveData?.length ? (
          <div className="container">
            <ObjectiveData result={lastQuestionnaireResearch[0]} />
          </div>
        ) : null}

        {questionnaireResearchList && (
          <div className="result-section">
            <div className="container">
              <h2 className="result-section__heading">История обследований</h2>

              <div className="result-list row">
                {questionnaireResearchList.map(item => (
                <div key={item.self} className="result-list__item">
                  <div className="result-list__item-wrapper">

                    <div className="result-list__info">
                      <h3 className="result-list__title">{item.name}</h3>
                      <div className="result-list__date">{formatDateTime(item.date, false)}</div>
                    </div>

                    <div className="result-list__buttons-wrapper">
                      <Link className="result-list__button button button-primary button-primary--mini" to={"/screening-result/" + item.id}>Смотреть</Link>
                      <a className="result-list__button button" target="_blank" download href={item.downloadLink}>Скачать</a>
                    </div>

                  </div>
                </div>))}

              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  questionnaireResearchList: state.questionnaire.questionnaireResearchList,
  lastQuestionnaireResearch: state.questionnaire.lastQuestionnaireResearch
})

const mapDispatchToProps = (dispatch) => ({
  fetchQuestionnaireResearchList: bindActionCreators(fetchQuestionnaireResearchListData, dispatch),
  fetchLastQuestionnaireResearch: bindActionCreators(fetchLastQuestionnaireResultData, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Personal);