import {profileModal as Action} from "../constants";

export const addFormError = (field, message) => ({
  type: Action.ADD_FORM_ERROR,
  data: {field, message}
})

export const removeAllFormErrors = () => ({
  type: Action.REMOVE_ALL_FORM_ERRORS
})

export const setIdentityType = (identityType) => ({
  type: Action.SET_IDENTITY_TYPE,
  data: identityType
})

export const setModalType = (modalType) => ({
  type: Action.SET_MODAL_TYPE,
  data: modalType
})

export const setIsShown = (isShown) => ({
  type: Action.SET_IS_SHOWN,
  data: isShown
})

export const setOptions = (options) => ({
  type: Action.SET_OPTIONS,
  data: options
})