import React, {useState} from 'react';
import {Decimal} from 'decimal.js';
import {useSelector} from "react-redux";

const Field = ({type, name, defaultValue, onChange, options = [], format}) => {
  const {values} = useSelector((state) => state.questionnaire);
  const [isError, setIsError] = useState(false);

  switch (type) {
    case "RADIO":
      return (
        <div className="question__flex-wrapper">
          {options.map((item, index) => (
            <div key={name + item.id} className="question__col">
              <button className={"question__button button"
              + (defaultValue === item.id ? " question__button--active":"")} onClick={() => onChange(item.id)}>{item.title}</button>
            </div>
          ))}
        </div>
      );
    case "SELECT":
      let value = defaultValue && !Array.isArray(defaultValue)? defaultValue.split(",") : [];

      return (
        <div className="question__flex-wrapper">
          {options.map((item, index) => (
          <label key={name + index} className="question__col question__checkbox">
            <input type="checkbox" name={name} value={item.id} onChange={(e) => {
              if (e.target.checked) {
                if (value.indexOf(item.id) === -1) {
                  value.push(item.id);
                }
              } else {
                if (value.indexOf(item.id) !== -1) {
                  value.splice(value.indexOf(item.id), 1);
                }
              }

              onChange(value.join(","))
            }}
                   defaultChecked={value[item.id]}/>
            <span className={"question__button button"
            + (value.indexOf(item.id) !== -1 ? " question__button--active":"")}>{item.title}</span>
          </label>))}
        </div>
      );
    case "NUMBER":
      return (
        <>
        <input className={"question__input" + (isError ? " input--invalid" : "")}
               key={name} type="text" name={name} onChange={(e) => {
          setIsError(false);

          e.target.value = e.target.value.replace(/,/gmi, ".");

          if (!e.target.value.match(/^\d*(?:\.\d*)?$/gi)) {
            if (!e.target.prevValue) {
              e.target.prevValue = "";
            }

            e.target.value = e.target.prevValue;
          }

          e.target.prevValue = e.target.value;

          let value = e.target.value;

          if (format?.max !== undefined && format?.min !== undefined) {
            let decimalStep = new Decimal(format?.step);
            let decimalValue = new Decimal(parseFloat(value));

            if (value > format.max || value < format.min || decimalValue.mod(decimalStep).toNumber() > 0) {
              setIsError(true);

              value = '';
            }
          }

          let systolic_pressure = values?.cardiovascular_diseases?.systolic_pressure;

          if (name === 'diastolic_pressure' && systolic_pressure) {
            if (+value >= +systolic_pressure) {
              setIsError(true);

              value = '';
            }
          }

          let diastolic_pressure = values?.cardiovascular_diseases?.diastolic_pressure;

          if (name === 'systolic_pressure' && diastolic_pressure) {
            if (+value <= +diastolic_pressure) {
              setIsError(true);

              value = '';
            }
          }

          return onChange(value)
        }}
               defaultValue={defaultValue}
               placeholder={format?.min !== undefined && format?.max !== undefined ? `${format.min}-${format.max}`: "" } />
          {isError && (name === 'diastolic_pressure' || name === 'systolic_pressure') ? (
            <span className={'input__error-message'}>Некорректный ввод</span>
          ) : null}
        </>
      );
    default:
      return (
        <input className="question__input" key={name} type="text" name={name} onChange={(e) => onChange(e.target.value)}
               defaultValue={defaultValue}/>
      );

  }
}

export default Field;