import React from 'react';

import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

class Dropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }
  }

  handleClick = () => {
    this.setState(state => ({
      isOpen: !state.isOpen
    }))
  }

  render() {
    let {children, title} = this.props;

    children = children?.replace(/(<table(?:(?!<\/table>).)*<\/table>)/smgi, "<div class='recommendations-item__table-wrapper'>$1</div>");

    let anons = "";

    children?.replace(/<p>((?:(?!<\/p>).)*)<\/p>/i, (match, p1) => {
      anons = p1.substr(0,200) + "...";
    })

    return (
      <div className="recommendations-item">
        <h3 className={"recommendations-item__heading" + (this.state.isOpen ? " recommendations-item__heading--opened" : "")} onClick={this.handleClick}>{title}</h3>
        <div className={"recommendations-item__anons" + (this.state.isOpen ? "  recommendations-item__anons--hidden" : "")} dangerouslySetInnerHTML={{__html: anons}} />
        <SlideDown className="recommendations-item__description">
          {this.state.isOpen ? (
            <div dangerouslySetInnerHTML={{__html: children}} />
          ) : null}
        </SlideDown>
      </div>
    )
  }
}

export default Dropdown;