import {common as Action} from '../constants';

export const commonReducer = (state = {}, action) => {
  switch (action.type) {
    case Action.START_LOADING: {
      let {isLoading} = state;

      return {...state, isLoading: isLoading + 1};
    }
    case Action.STOP_LOADING: {
      let {isLoading} = state;

      isLoading--;

      return {...state, isLoading: isLoading < 0 ? 0 : isLoading};
    }
    default:
      return state;
  }
}