import store from "../store/store";
import {removeProfile, removeToken} from "../store/actions/personalActions";
import {showMessageModal} from "../store/actions/messageModalActions";
import { createClient } from "hal-rest-client";
import {
  removeLastQuestionnaireResearch,
  removeQuestionnaireResearch,
  removeQuestionnaireResearchList
} from "../store/actions/questionnaireActions";

const welcomeUrl = process.env.REACT_APP_API_URL + "/app/rest/myapi/open/welcome";

class ApiService {
  static client = null;
  static baseResource = null;
  static responses = [];

  static init() {
    let client = createClient(welcomeUrl);

    client.interceptors.request.use(function (config) {
      let state = store?.getState();

      if (state) {
        let token = state?.personal?.token?.access_token;

        if (token) {
          config.headers = {Authorization: `Bearer ${token}`};
        }
      }

      return config;
    }, function (error) {
      store?.dispatch(showMessageModal({
        title: "Неизвестная ошибка",
        message: "Произошла ошибка, пожалуйста, повторите операцию позже"
      }));

      return Promise.reject(error);
    });

    client.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      switch (error?.response?.status) {
        case 401:
          /*if (error.config && error.response && error.response.status === 401) {
            return updateToken().then((token) => {
              error.config.headers.xxxx <= set the token
              return client.axios.request(error.config);
            });
          }*/
          /*let refreshToken = bindActionCreators(refreshToken, store?.dispatch);

          refreshToken();*/

          store?.dispatch(removeToken());
          store?.dispatch(removeProfile());
          store?.dispatch(removeQuestionnaireResearch());
          store?.dispatch(removeQuestionnaireResearchList());
          store?.dispatch(removeLastQuestionnaireResearch());
          window.location.hash = "/";
          store?.dispatch(showMessageModal({
            title: "Вы не авторизованы в системе",
            text: "Ваша сессия была завершена, пожалуйста, авторизуйтесь."
          }));

          break;
        case 403:
          store?.dispatch(showMessageModal({
            title: "Вам не доступно данное действие",
            text: "Авторизуйтесь или обратитесь в техподдержку"
          }));

          break;

        case 500:
          store?.dispatch(removeToken());
          store?.dispatch(removeProfile());
          store?.dispatch(removeQuestionnaireResearch());
          store?.dispatch(removeQuestionnaireResearchList());
          store?.dispatch(removeLastQuestionnaireResearch());
          window.location.hash = "/";
          /*store?.dispatch(showMessageModal({
            title: "Ошибка",
            text: "Произошла непредвиденная ошибка, пожалуйста, повторите операцию позже"
          }));*/

          break;

        default:
      }

      return Promise.reject(error);
    });

    this.client = client;
  }

  static async loadBaseResource(forced = false) {
    if (!this.baseResource || forced) {
      this.baseResource = await this.client.fetchResource(welcomeUrl);
    }
  }
}

export default ApiService;
