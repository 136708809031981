import React from "react";
import {connect} from "react-redux";
import {acceptCookies} from "../../store/actions/personalActions";

const CookiesPanel = ({isCookiesAccepted, acceptCookies}) => (
  <div className="cookies-panel" style={{"display": isCookiesAccepted ? "none" : "display"}}>
    <div className="container">
      <div className="cookies-panel__flex-wrapper">
        <div className="cookies-panel__column">
          <h6 className="cookies-panel__heading">Мы используем cookie</h6>
          <p className="cookies-panel__description">Используя  этот  сайт, Вы даете  согласие на использование файлов cookie для улучшения качества работы</p>
        </div>

        <div className="cookies-panel__column">
          <button className="cookies-panel__button button button-primary--mini button-primary" onClick={acceptCookies}>Принять</button>
        </div>
      </div>
    </div>
  </div>
)

const mapStateToProps = state => ({
  isCookiesAccepted: state.personal.isCookiesAccepted,
})

const mapDispatchToProps = dispatch => ({
  acceptCookies() {
    dispatch(acceptCookies());
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CookiesPanel);