import React from "react";

export const InfluenceOfFactors = ({result}) => {
  let influenceOfFactorsArray = [[], [], [], []];

  for (let item in result?.influenceOfFactors) {
    let index = result?.influenceOfFactors[item];

    if (index >= 0 && index <= 3) {
      influenceOfFactorsArray[index].push(item);
    }
  }

  return (
    <div>
      <div className="influence-of-risk__wrapper">
        <div className="influence-of-risk__column influence-of-risk__column--does-not-affect">
          {influenceOfFactorsArray[0]?.length ? (
            <div>
              <ul className="influence-of-risk__list">
                {influenceOfFactorsArray[0].map(item => (
                  <li key={item} className="influence-of-risk__list-item">{item}</li>
                ))}
              </ul>

              <div className="influence-of-risk__column-heading-wrapper">
                <p className="influence-of-risk__column-heading">Не влияет</p>
              </div>


              <div className="influence-of-risk__marker scale-marker">
                <svg className="scale-marker__triangle" width="28" height="31" viewBox="0 0 28 31" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.5377 11.6244L12.8774 26.6244C12.4925 27.291 11.5303 27.291 11.1454 26.6244L2.48511 11.6244C2.10021 10.9577 2.58133 10.1244 3.35113 10.1244L20.6716 10.1244C21.4414 10.1244 21.9226 10.9577 21.5377 11.6244Z"
                    stroke="white" strokeWidth="2"/>
                </svg>

                <div className="scale-marker__marker"/>
              </div>
            </div>
          ) : null}

        </div>

        <div className="influence-of-risk__column influence-of-risk__column--minor-influence">
          {influenceOfFactorsArray[1]?.length ? (
            <div>
              <ul className="influence-of-risk__list">
                {influenceOfFactorsArray[1].map(item => (
                  <li key={item} className="influence-of-risk__list-item">{item}</li>
                ))}
              </ul>


              <div className="influence-of-risk__column-heading-wrapper">
                <p className="influence-of-risk__column-heading">Умеренное влияние</p>
              </div>

              <div className="influence-of-risk__marker scale-marker">
                <svg className="scale-marker__triangle" width="28" height="31" viewBox="0 0 28 31" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.5377 11.6244L12.8774 26.6244C12.4925 27.291 11.5303 27.291 11.1454 26.6244L2.48511 11.6244C2.10021 10.9577 2.58133 10.1244 3.35113 10.1244L20.6716 10.1244C21.4414 10.1244 21.9226 10.9577 21.5377 11.6244Z"
                    stroke="white" strokeWidth="2"/>
                </svg>

                <div className="scale-marker__marker"/>
              </div>
            </div>
          ) : null}
        </div>

        <div className="influence-of-risk__column influence-of-risk__column--moderate-impact">
          {influenceOfFactorsArray[2]?.length ? (
            <div>
              <ul className="influence-of-risk__list">
                {influenceOfFactorsArray[2].map(item => (
                  <li key={item} className="influence-of-risk__list-item">{item}</li>
                ))}
              </ul>


              <div className="influence-of-risk__column-heading-wrapper">
                <p className="influence-of-risk__column-heading">Сильное влияние</p>
              </div>

              <div className="influence-of-risk__marker scale-marker">
                <svg className="scale-marker__triangle" width="28" height="31" viewBox="0 0 28 31" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.5377 11.6244L12.8774 26.6244C12.4925 27.291 11.5303 27.291 11.1454 26.6244L2.48511 11.6244C2.10021 10.9577 2.58133 10.1244 3.35113 10.1244L20.6716 10.1244C21.4414 10.1244 21.9226 10.9577 21.5377 11.6244Z"
                    stroke="white" strokeWidth="2"/>
                </svg>

                <div className="scale-marker__marker"/>
              </div>
            </div>
          ) : null}

        </div>

        <div className="influence-of-risk__column influence-of-risk__column--strong-influence">
          {influenceOfFactorsArray[3]?.length ? (
            <div>
              <ul className="influence-of-risk__list">
                {influenceOfFactorsArray[3].map(item => (
                  <li key={item} className="influence-of-risk__list-item">{item}</li>
                ))}
              </ul>

              <div className="influence-of-risk__column-heading-wrapper">
                <p className="influence-of-risk__column-heading">Очень сильное влияние</p>
              </div>

              <div className="influence-of-risk__marker scale-marker">
                <svg className="scale-marker__triangle" width="28" height="31" viewBox="0 0 28 31" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21.5377 11.6244L12.8774 26.6244C12.4925 27.291 11.5303 27.291 11.1454 26.6244L2.48511 11.6244C2.10021 10.9577 2.58133 10.1244 3.35113 10.1244L20.6716 10.1244C21.4414 10.1244 21.9226 10.9577 21.5377 11.6244Z"
                    stroke="white" strokeWidth="2"/>
                </svg>

                <div className="scale-marker__marker"/>
              </div>

            </div>
          ) : null}
        </div>

      </div>

      <ul className="influence-of-risk__legend">
        <li className="influence-of-risk__legend-item influence-of-risk__legend-item--does-not-affect">Не
          влияет
        </li>
        <li
          className="influence-of-risk__legend-item influence-of-risk__legend-item--minor-influence">Умеренное влияние
        </li>
        <li className="influence-of-risk__legend-item influence-of-risk__legend-item--moderate-impact">Сильное влияние
        </li>
        <li className="influence-of-risk__legend-item influence-of-risk__legend-item--strong-influence">Очень сильное влияние
        </li>
      </ul>
    </div>
  )
}