import React from "react";
import {connect} from "react-redux";

const Loading = ({isLoading}) => (
  <div className="loading" style={{display: isLoading ? "block" : "none"}}/>
)

const mapStateToProps = state => ({
  isLoading: state.common.isLoading,
})

const mapDispatchToProps = (dispatch) => ({

})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loading);
