import React from 'react';
import { Questionnaire } from "../index";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchQuestionnaireData} from "../../store/actionCreators/questionnaireActionCreator";
import {withRouter} from "react-router-dom";

class QuestionnairePage extends React.Component {
  componentDidMount() {
    let { questionnaireCode } = this.props.match.params;

    if (!questionnaireCode) {
      questionnaireCode = this.props.questionnaireCode;
    }

    this.props.fetchQuestionnaireData(questionnaireCode);
  }

  render() {
    return (
      <Questionnaire {...this.props}/>
    )
  }
}

const mapStateToProps = (state) => ({
  router: state.router
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchQuestionnaireData: (code) => bindActionCreators(() => (fetchQuestionnaireData(code)), dispatch)()
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(QuestionnairePage));
