import React from "react";
import {bindActionCreators} from "redux";
import {
  fetchQuestionnaireListData,
} from "../../store/actionCreators/questionnaireActionCreator";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {QuestionnaireType} from "../../utils/constants";

class HoblQuestionnaireList extends React.Component {
  componentDidMount() {
    this.props.fetchQuestionnaireList();
  }

  render() {
    const baseUrl = this.props.baseUrl || "";

    return (
      <div className="main-container__inner depression-questionnaire">
        <div className="container">

          <h1 className="main-container__header depression-questionnaire__header heading">Скрининг «Бронхолегочные заболевания»</h1>

          <div className="depression-questionnaire__list row">
            <div className="col">
              <div className="depression-questionnaire__item questionnaire-card-2">
                <h2 className="questionnaire-card-2__title icon-hobl-1">Хроническая обструктивная болезнь легких (ХОБЛ)</h2>
                <Link className="questionnaire-card-2__button button button-primary button-primary--mini"
                      to={baseUrl + "/questionnaire/" + this.props.questionnaireList?.find(item => item.code === QuestionnaireType.HOBL)?.code}>Начать</Link>
              </div>
            </div>

            <div className="col">
              <div className="depression-questionnaire__item questionnaire-card-2">
                <h2 className="questionnaire-card-2__title icon-hobl-2">Оценочный тест тяжести течения ХОБЛ</h2>
                <Link className="questionnaire-card-2__button button button-primary button-primary--mini"
                      to={baseUrl + "/questionnaire/" + this.props.questionnaireList?.find(item => item.code === QuestionnaireType.SEVERITY_OF_HOBL)?.code}>Начать</Link>
              </div>
            </div>

            <div className="col">
              <div className="depression-questionnaire__item questionnaire-card-2">
                <h2 className="questionnaire-card-2__title icon-hobl-3">Контроль над астмой</h2>
                <Link className="questionnaire-card-2__button button button-primary button-primary--mini"
                      to={baseUrl + "/questionnaire/" + this.props.questionnaireList?.find(item => item.code === QuestionnaireType.ASTHMA)?.code}>Начать</Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  questionnaireList: state.questionnaire.questionnaireList
})

const mapDispatchToProps = (dispatch) => ({
  fetchQuestionnaireList: bindActionCreators(fetchQuestionnaireListData, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HoblQuestionnaireList);
