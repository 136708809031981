import {questionnaire as Action} from '../constants';

export const questionnaireReducer = (state = {}, action) => {
  switch (action.type) {
    case Action.SET_QUESTIONNAIRE_LIST: {
      return {...state, questionnaireList: action.data}
    }
    case Action.SET_QUESTIONNAIRE: {
      let {values, questionnaire, currentModuleIndex} = state;

      if (!values || Array.isArray(values)) {
        values = {}
      }

      if (!values[action.data.code] || Array.isArray(values[action.data.code])) {
        values[action.data.code] = {};
      }

      currentModuleIndex = questionnaire.code === action.data.code ? currentModuleIndex : 0;

      return {...state, ...setAvailabilityAndRemoveNotAvailableValues(action.data, values), currentModuleIndex: currentModuleIndex};
    }
    case Action.TO_NEXT_MODULE: {
      let currentModuleIndex = state.currentModuleIndex + 1 === state.questionnaire.modules.length ?
        state.currentModuleIndex : ++state.currentModuleIndex

      return {...state, currentModuleIndex: currentModuleIndex }
    }
    case Action.TO_PREV_MODULE: {
      let currentModuleIndex = state.currentModuleIndex === 0 ?
        state.currentModuleIndex : --state.currentModuleIndex

      return {...state, currentModuleIndex: currentModuleIndex }
    }
    case Action.SET_VALUE: {
      let {questionnaire, values} = state;

      const {id, value} = action.data;

      values[questionnaire.code][id] = value;

      return {...state, ...setAvailabilityAndRemoveNotAvailableValues(questionnaire, values)};
    }
    case Action.RESET_VALUES: {
      let {values, questionnaire} = state;

      values[action.data] = {};

      return {...state, ...setAvailabilityAndRemoveNotAvailableValues(questionnaire, Object.assign({}, values)), currentModuleIndex: 0};
    }
    case Action.SET_QUESTIONNAIRE_RESEARCH_LIST: {
      return {...state, questionnaireResearchList: action.data }
    }
    case Action.SET_QUESTIONNAIRE_RESEARCH: {
      return {...state, questionnaireResearch: action.data }
    }
    case Action.SET_LAST_QUESTIONNAIRE_RESEARCH: {
      return {...state, lastQuestionnaireResearch: action.data }
    }
    case Action.REMOVE_QUESTIONNAIRE_RESEARCH_LIST: {
      return {...state, questionnaireResearchList: null }
    }
    case Action.REMOVE_QUESTIONNAIRE_RESEARCH: {
      return {...state, questionnaireResearch: null }
    }
    case Action.REMOVE_LAST_QUESTIONNAIRE_RESEARCH: {
      return {...state, lastQuestionnaireResearch: null }
    }
    default:
      return state;
  }
}

function setAvailabilityAndRemoveNotAvailableValues(questionnaire, values) {
  questionnaire?.modules?.forEach((module, moduleIndex) => {
    questionnaire.modules[moduleIndex].isAnswered = true;

    module.questions.forEach((question, questionIndex) => {
      let condition = question?.condition;

      if (!condition) {
        questionnaire.modules[moduleIndex].questions[questionIndex].available = true;
      } else {
        questionnaire.modules[moduleIndex].questions[questionIndex].available =
          isAvailable(condition, values[questionnaire.code]);
      }

      if (!questionnaire.modules[moduleIndex].questions[questionIndex].available) {
        delete values[questionnaire.code][questionnaire.modules[moduleIndex].questions[questionIndex].code];
      }

      if (questionnaire.modules[moduleIndex].questions[questionIndex].available &&
        !values[questionnaire.code][questionnaire.modules[moduleIndex].questions[questionIndex].code] &&
        !questionnaire.modules[moduleIndex].questions[questionIndex].isOptional) {
        questionnaire.modules[moduleIndex].isAnswered = false;
      }
    })
  });

  return {questionnaire, values};
}

function isAvailable(condition, values) {
  switch (condition.type) {
    case "condition":
      return values[condition.condition.id] === condition.condition.value;
    case "or": {
      let result = false;

      condition.conditions.forEach(item => {
        result = result || isAvailable(item, values);
      })

      return result;
    }
    case "and": {
      let result = true;

      condition.conditions.forEach(item => {
        result = result && isAvailable(item, values);
      })

      return result;
    }
    default:
      return false;
  }
}
