import React from "react";
import {declOfNum} from "../../utils/renderHelpers";

export const PointedInterpretativeResultScale = ({results}) => (
  <div className="scale-3">
    {results?.map(item => (
      <div key={item.name} className="scale-3__item scale-3-item">
        {item.to === null && (
          <div className="scale-3-item__value">&gt; {item.from} {declOfNum(item.from, ["балла", "баллов", "баллов"])}</div>
        )}

        {item.from === null && (
          <div className="scale-3-item__value">&lt; {item.to} {declOfNum(item.to, ["балла", "баллов", "баллов"])}</div>
        )}

        {item.from !== null && item.to !== null && (
          <div className="scale-3-item__value">{item.from}-{item.to} {declOfNum(item.to, ["балла", "баллов", "баллов"])}</div>
        )}

        <div className="scale-3-item__title">{item.name}</div>
      </div>
    ))}
  </div>
)