import React from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";

const Navigation = ({isTokenExists}) => {
  return (
    <ul className="header-nav__list">

      {isTokenExists && (
      <li className="header-nav__list">
        <Link className="header-nav__link button button-link button-link--gray" to={"/personal"}>Личный кабинет</Link>
      </li>
      )}

      <li className="header-nav__list">
        <Link className="header-nav__link button button-link button-link--gray" to={"/questionnaires"}>Пройти обследование</Link>
      </li>

    </ul>
  )
}

const mapStateToProps = (state) => ({
  isTokenExists: state.personal?.token?.access_token
})

export default connect(
  mapStateToProps
)(Navigation);