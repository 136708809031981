import React from "react";

export const PrivacyPolicy = () => (
  <div className="main-container__inner depression-questionnaire">
    <div className="container">

      <h1 className="main-container__header depression-questionnaire__header heading">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ</h1>

      <div className="main-container__description main-container__description--no-padding-bottom">
        <p>
          Общество с ограниченной ответственностью «Медицинская технологическая компания» (далее – ООО «МТК»),
          являющееся администратором сайта https://escreen.online/, обязуется защищать и уважать право на
          конфиденциальность Ваших данных. В настоящей политике конфиденциальности и обработки персональных
          данных (далее – «Политика конфиденциальности») указаны способы, процесс и цели сбора и обработки данных
          ООО «МТК». Предоставляемые Вами данные являются конфиденциальными и подлежат разглашению только при
          обстоятельствах, изложенных в Политике конфиденциальности и предусмотренных законодательством РФ.
        </p>

        <p>
          Загружая наш сайт на своем компьютере, мобильном или ином устройстве, регистрируясь в качестве пользователя
          сайта, заполняя электронные формы опросов, содержащие Ваши данные, а также передавая данные ООО «МТК» любым
          иным способом, Вы соглашаетесь на условия, описанные в Политике конфиденциальности и даете ООО «МТК» разрешение
          на их обработку.
        </p>

        <p>
          ООО «МТК» собирает и обрабатывает обезличенные персональные данные, не позволяющие без
          использования дополнительной информации определить принадлежность персональных данных конкретному субъекту.
          Мы гарантируем добросовестный сбор данных, их защиту и сохранение полной конфиденциальности в отношении их
          владельцев.
        </p>

        <p><b>Общие положения</b></p>

        <p>
          Политика конфиденциальности составлена в соответствии с требованиями Федерального закона от 27.07.2006 № 152-ФЗ
          (в ред. от 30.12.2020) "О персональных данных" (с изм. и доп., вступ. в силу с 01.03.2021) и определяет
          порядок работы с персональными данными, правила защиты персональных данных, права, обязанности и
          ответственность лиц, имеющих доступ к персональным данным.
        </p>

        <p>Политика конфиденциальности применяется ко всей информации, которую ООО «МТК» может
          получить о посетителях сайта.
        </p>

        <p><b>Основные термины, применяемые в Политике конфиденциальности</b></p>

        <p className="c0"><b>Персональные данные</b><span
          className="c8">&nbsp;(далее </span><span className="c2">-</span><span className="c8">&nbsp;«Персональные данные») </span><span
          className="c2">-</span><span className="c1">&nbsp;любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных).</span>
        </p><p className="c0"><b>Субъект персональных данных</b><span
          className="c8">&nbsp;</span><span className="c2">-</span><span className="c1">&nbsp;физическое лицо (посетитель (пользователь) сайта ООО «МТК», потребитель услуг ООО «МТК», в том числе потенциальный или активный), к которому относятся обрабатываемые ООО «МТК» персональные данные. Применительно к Политике конфиденциальности это физическое лицо, имеющее намерение пройти скрининг, связанный с оценкой и управлением состоянием здоровья, исключительно для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности.</span>
        </p><p className="c0"><b>Оператор персональных данных</b><span className="c8">&nbsp;</span><span
          className="c2">-</span><span className="c8">&nbsp;ООО «МТК» ОГРН 1187746199882, ИНН 7731399263, КПП 773101001, юридический адрес:</span><span
          className="c9">&nbsp;</span><span
          className="c8">121108, город Москва, ул. Пивченкова, д. 2, кв. 4,</span><span
          className="c9">&nbsp;</span><span className="c1">самостоятельно или совместно с другими лицами организующее и (или) осуществляющее обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными.</span>
        </p><p className="c0"><b>Обработка персональных данных</b><span
          className="c8">&nbsp;</span><span className="c2">-</span><span className="c1">&nbsp;любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, обновление, изменение, извлечение, использование, передачу (распространение, предоставление доступа), обезличивание, блокирование, удаление, уничтожение персональных данных. </span>
        </p><p className="c0"><b>Автоматизированная обработка персональных данных</b><span
          className="c1">&nbsp;– обработка персональных данных с помощью средств вычислительной техники.</span></p><p
          className="c0"><span className="c2">Сайт</span><span className="c8">&nbsp;–</span><span
          className="c9">&nbsp;совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по адресу: </span><span
          className="c1">https://escreen.online/</span></p>
        <p className="c0">
        <b>Обезличивание персональных данных</b><span
          className="c8">&nbsp;</span><span className="c2">-</span><span className="c1">&nbsp;действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному субъекту персональных данных.</span>
        </p><p className="c0"><b>Уничтожение персональных данных</b><span
          className="c8">&nbsp;</span><span className="c2">-</span><span className="c1">&nbsp;любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) уничтожаются материальные носители персональных данных.</span>
        </p><p className="c0"><b>Информационная система персональных данных</b><span
          className="c8">&nbsp;</span><span className="c2">-</span><span className="c1">&nbsp;совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств.</span>
        </p><p className="c0"><b>Распространение персональных данных</b><span
          className="c8">&nbsp;</span><span className="c2">-</span><span className="c8">&nbsp;д</span><span
          className="c1">ействия, направленные на раскрытие персональных данных неопределенному кругу лиц.</span></p><p className="c10"><b>Сбор, дальнейшее использование персональных данных, перечень и цели их обработки</b>
        </p><p className="c0"><span className="c1">Персональные данные запрашиваются ООО «МТК» с целью Вашей регистрации на Сайте, для ознакомления с его содержанием и прохождения скрининговых опросов, позволяющих понять свое самочувствие, выявить риски определенных заболеваний и сформулировать вопросы к лечащему врачу для получения последующей профессиональной консультации. </span>
        </p><p className="c0"><span className="c1">Полный перечень запрашиваемых данных: возраст (количество полных лет), пол, уровень образования (начальное, среднее, высшее), контактные данные (телефон и (или) адрес электронной почты). В процессе прохождения опросов пользователь Сайта предоставляет данные, относящиеся к состоянию здоровья, позволяющие выявить симптоматику ряда заболеваний, и получить представление о индивидуальных факторах риска. </span>
        </p><p className="c0"><span className="c8">Также на сайте происходит сбор и обработка обезличенных данных о посетителях (файлов cookie</span><span
          className="c9">&nbsp;- </span><span className="c1">небольших фрагментов данных, отправленный веб-сервером и хранимых на компьютере пользователя. Они применяется для сохранения данных на стороне пользователя и используется для аутентификации, хранения персональных настроек, отслеживания состояния сеанса доступа к Сайту, анализу статистических сведений) с помощью сервисов интернет-статистики.</span>
        </p><p className="c0"><span className="c1">Данные, собираемые ООО «МТК» о состоянии здоровья при прохождении опросов, не подлежат разглашению, за исключением обработки таких данных в статистических или иных исследовательских целях, с условием их обязательного обезличивания в соответствии с п. 9 ст. 6 ФЗ № 152 "О персональных данных". </span>
        </p><p className="c0"><span className="c1">Собранные в результате опросов данные, также могут быть предоставлены самим Субъектом персональных данных непосредственно лечащему врачу в медико-профилактических целях, в целях установления медицинского диагноза, оказания медицинских и медико-социальных услуг, что отвечает требованиям п. 4. Ст. 10 ФЗ № 152 "О персональных данных".</span>
        </p><p className="c10"><b>Организация хранения и меры, принимаемые для защиты персональных данных </b>
        </p><p className="c0"><span className="c1">ООО «МТК» гарантирует, что все полученные данные хранятся в защищенной системе. Предоставленные персональные данные защищены от несанкционированного доступа к ним, изменения, использования третьими лицами, разглашения, уничтожения любыми лицами, что обеспечивается при помощи технических и программных средств защиты, в том числе использованием протокола HTTPS. Местом сбора и хранения персональных данных является хостинг https:\\ruvds.com\ расположенный на территории Российской Федерации. </span>
        </p><p className="c0"><span className="c8">Лица, получившие доступ к обрабатываемым персональным данным, имеют перед ООО «МТК» обязательство о неразглашении конфиденциальной информации, а также предупреждены о возможной дисциплинарной, административной, гражданско-правовой и уголовной ответственности в случае нарушения требований действующего законодательства Российской Федерации в области защиты персональных данных.</span><span
          className="c4 c12">&nbsp;</span></p><p className="c0"><span className="c1">Лица, имеющие доступ к обрабатываемым персональным данным, не вправе сообщать персональные данные третьим лицам без письменного согласия Субъекта персональных данных, за исключением случаев, когда это необходимо в целях предупреждения угрозы жизни и здоровью.</span>
        </p><p className="c0"><span className="c1">Срок обработки персональных данных является неограниченным. Субъект персональных данных может в любой момент отозвать свое согласие на обработку персональных данных, направив в ООО «МТК» уведомление посредством электронной почты на адрес _______________&nbsp;с указанием темы письма: «Отзыв согласия на обработку персональных данных». Уведомление должно содержать регистрационный номер пользователя Сайта и быть направлено с электронного адреса, с которого производилась регистрация на Сайте или почтовым отправлением на юридический адрес Оператора персональных данных.</span>
        </p><p className="c10"><b>Информация, размещенная на Сайте и правовой режим ее использования</b>
        </p><p className="c0 c7"><span className="c1">Вся информация на Сайте размещается «как есть» («as is») с возможными ошибками, без гарантий любого вида, прямо выраженных или подразумеваемых. Сайт, его дизайн, изображения, фотографии, текст, графика, логотипы, а также всё программное обеспечение Сайта защищены юридически. В частности, авторские права на содержание сайта, в том числе методика проведения скрининговых опросов и дизайн-концепция Сайта принадлежат ООО «МТК».</span>
        </p><p className="c0 c7"><span className="c1">Пользователи Сайта не приобретают прав ни на Сайт, ни на контент, ни на содержащуюся на Сайте информацию и данные, при этом под контентом ООО «МТК» в соответствии с разъяснениями, содержащимися в постановлении Президиума ВАС № 255/08 от 22.04.2008, подразумеваются специально подобранные и расположенные определенным образом материалы (тексты, изображения, фотографии, и т.д.), которые могут быть использованы с помощью компьютерной программы (компьютерного кода), являющегося элементом Сайта.</span>
        </p><p className="c0 c7"><span className="c1">Фотографии, доступные на Сайте, принадлежат ООО «МТК», либо лицам, предоставившим соответствующие права на их использование, вследствие чего эти объекты могут использоваться третьими лицами исключительно не в коммерческих целях, с разрешения правообладателя и с обязательным указанием авторских прав.</span>
        </p><p className="c7 c10"><b>Заключительные положения</b>
        </p><p className="c0" id="h.gjdgxs"><span
          className="c1">Любые разъяснения по вопросам, связанным с обработкой персональных данных, пользователь Сайта может получить, обратившись к ООО «МТК» по адресу: info@escreen.online.</span>
        </p><p className="c11"><span className="c1">ООО «МТК» оставляет за собой право вносить изменения в Политику конфиденциальности, уведомляя пользователей одним из способов по своему усмотрению: путем размещения новой редакции Политики конфиденциальности на Сайте, путем отправки сообщений на адрес электронной почты и/или другими способами. Политика действует бессрочно до замены новой редакцией.</span>
        </p><p className="c3 c13"><span className="c1"></span></p>
      </div>

    </div>
  </div>
)