import React from 'react';
import {Link} from "react-router-dom";

const NotExistsPage = ({withHomeLink = true}) => {
  return (
    <div className="container">
      <h1>Запрашиваемая вами страница не существует</h1>
      {withHomeLink && (
        <Link to="/questionnaires">Главная</Link>
      )}
    </div>
  )
}

export default NotExistsPage;
