const common = {
  START_LOADING: "START_LOADING",
  STOP_LOADING: "STOP_LOADING"
}

const questionnaire = {
  SET_QUESTIONNAIRE_LIST: "SET_QUESTIONNAIRE_LIST",
  SET_QUESTIONNAIRE: "SET_QUESTIONNAIRE",
  TO_NEXT_MODULE: "TO_NEXT_MODULE",
  TO_PREV_MODULE: "TO_PREV_MODULE",
  SET_VALUE: "SET_VALUE",
  RESET_VALUES: "RESET_VALUES",
  SET_QUESTIONNAIRE_RESEARCH_LIST: "SET_QUESTIONNAIRE_RESEARCH_LIST",
  SET_QUESTIONNAIRE_RESEARCH: "SET_QUESTIONNAIRE_RESEARCH",
  SET_LAST_QUESTIONNAIRE_RESEARCH: "SET_LAST_QUESTIONNAIRE_RESEARCH",
  REMOVE_LAST_QUESTIONNAIRE_RESEARCH: "REMOVE_LAST_QUESTIONNAIRE_RESEARCH",
  REMOVE_QUESTIONNAIRE_RESEARCH_LIST: "REMOVE_QUESTIONNAIRE_RESEARCH_LIST",
  REMOVE_QUESTIONNAIRE_RESEARCH: "REMOVE_QUESTIONNAIRE_RESEARCH"
}

const personal = {
  SET_IS_REFRESH_NEEDED: "SET_IS_REFRESH_NEEDED",
  SET_TOKEN: "SET_TOKEN",
  REMOVE_TOKEN: "REMOVE_TOKEN",
  SET_PROFILE: "SET_PROFILE",
  REMOVE_PROFILE: "REMOVE_PROFILE",
  SET_TICKET: "SET_TICKET",
  SET_CLINIC_ID: "SET_CLINIC_ID",
  ACCEPT_COOKIES: "ACCEPT_COOKIES"
}

const profileModal = {
  SET_IS_SHOWN: "SET_IS_SHOWN",
  SET_IDENTITY_TYPE: "SET_IDENTITY_TYPE",
  SET_MODAL_TYPE: "SET_MODAL_TYPE",
  ADD_FORM_ERROR: "ADD_FORM_ERROR",
  REMOVE_ALL_FORM_ERRORS: "REMOVE_ALL_FORM_ERRORS",
  SET_OPTIONS: "SET_OPTIONS"
}

const messageModal = {
  SHOW_MESSAGE_MODAL: "SHOW_MESSAGE_MODAL",
  HIDE_MESSAGE_MODAL: "HIDE_MESSAGE_MODAL",
  SHOW_OFFER_TO_REGISTER_MODAL: "SHOW_OFFER_TO_REGISTER_MODAL",
  HIDE_OFFER_TO_REGISTER_MODAL: "HIDE_OFFER_TO_REGISTER_MODAL"
}

export {questionnaire, personal, profileModal, messageModal, common};