import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import {
  setOptions,
} from "../../store/actions/profileModalActions";
import {ModalType} from "../../utils/constants";

const HomeHeaderProfile = ({profile, openProfileLoginModal}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsOpen(false);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize)
  }, []);

  return (
    <>
      <div className="header__cabinet">
        {profile ? (
          <a className="header__cabinet-link button button-link" href='/cabinet'>ID {profile?.id}</a>
        ) : (
          <button className="header__cabinet-link button button-link" onClick={openProfileLoginModal}>Войти</button>
        )}
      </div>

      <button className={"header__mobile-menu hidden-xl" + (isOpen ? " header__mobile-menu--opened" : "")} onClick={() => setIsOpen(!isOpen)}/>

      <div className={"header__mobile-nav mobile-nav" + (isOpen ? " mobile-nav--opened" : "")}>
        <ul className="mobile-nav__list">
          <li className="mobile-nav__item">
            {profile ? (
              <a className="mobile-nav__link button button-link" href='/cabinet'>ID {profile?.id}</a>
            ) : (
              <button className="mobile-nav__link button button-link" onClick={openProfileLoginModal}>Войти</button>
            )}
          </li>

          <li className="mobile-nav__item">
            <a className="mobile-nav__link button button-link" href="#how-it-works" onClick={() => setIsOpen(false)}>Принцип работы</a>
          </li>

          <li className="mobile-nav__item">
            <a className="mobile-nav__link button button-link" href="#prevention" onClick={() => setIsOpen(false)}>Пройти обследование</a>
          </li>

          <li className="mobile-nav__item">
            <a className="mobile-nav__link button button-link" href="#about" onClick={() => setIsOpen(false)}>О нас</a>
          </li>

          <li className="mobile-nav__item">
            <a className="mobile-nav__link button button-link" href="#contacts" onClick={() => setIsOpen(false)}>Контакты</a>
          </li>

        </ul>

        <a className="mobile-nav__contact button button-link" href={window?.contacts?.phone?.href}>{window?.contacts?.phone?.title}</a>

        <ul className="mobile-nav__social social social--mini">

          <li className="social__item">
            <a className="social__link icon-vk" href={window?.contacts?.socials?.vk} />
          </li>

          <li className="social__item">
            <a className="social__link icon-instagram-2" href={window?.contacts?.socials?.instagram} />
          </li>

          <li className="social__item">
            <a className="social__link icon-facebook-2" href={window?.contacts?.socials?.facebook} />
          </li>

        </ul>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  profile: state.personal.profile,
})

const mapDispatchToProps = dispatch => ({
  openProfileLoginModal() {
    dispatch(setOptions({
      modalType: ModalType.LOGIN,
      registrationTitle: null,
      isShown: true,
      text: null,
      isOnlyOneAction: false,
      isNotChooseIdentity: false,
      mainButtonText: null,
      additionalButton: null,
      isEmailSend: false,
      isDownloadResearch: false
    }))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeHeaderProfile);