export default function() {
  let headerNav = document.querySelector(".header");

  setHeaderWidth();

  window.addEventListener('scroll', function() {
    if (headerNav) {
      if (window.pageYOffset > 0) {
        if (!headerNav.classList.contains("header--scrolled")) {
          headerNav.classList.add("header--scrolled");
        }
      } else {
        if (headerNav.classList.contains("header--scrolled")) {
          headerNav.classList.remove("header--scrolled");
        }
      }
    }
  });

  window.addEventListener("resize", setHeaderWidth)

  function setHeaderWidth() {
    if (headerNav) {
      headerNav.style.width = document.documentElement.clientWidth + "px";
    }
  }
}
