import React, {useEffect} from 'react';
import {
  Route,
  Switch,
  useRouteMatch,
  useParams, useHistory
} from "react-router-dom";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import {QuestionnaireListPage} from "../QuestionnaireListPage/QuestionnaireListPage";
import NotExistsPage from "../NotExistsPage/NotExistsPage";
import {DepressionQuestionnaireList, QuestionnairePage} from "../index";
import HoblQuestionnaireList from "../HoblQuestionnaireList/HoblQuestionnaireList";
import ResultPage from "../ResultPage/ResultPage";
import {useDispatch} from "react-redux";
import {setClinicId} from "../../store/actions/personalActions";

export const IsolatedCabinetWrapper = ({defaultClinicId, page}) => {
  const params = useParams();

  let clinicId = defaultClinicId ? defaultClinicId : params.clinicId;
  let { url } = useRouteMatch();

  let history = useHistory();
  let dispatch = useDispatch();

  if (!defaultClinicId) {
    const clinicIdsWhiteList = process?.env.REACT_APP_CCM_CLINIC_IDS_WHITE_LIST || '';

    if (clinicIdsWhiteList.split(',').indexOf(clinicId) === -1) {
      history.push('/not-found')
    }
  }

  useEffect(() => {
    dispatch(setClinicId(clinicId))
  }, [clinicId])

  return (
    <div>
      <Switch>
        <Route path={`${url}/questionnaires`}>
          <ScrollToTop/>
          <QuestionnaireListPage baseUrl={url} page={page} openInNewWindow={true}/>
        </Route>
        <Route path={`${url}/depression-questionnaires`}>
          <ScrollToTop/>
          <DepressionQuestionnaireList baseUrl={url}/>
        </Route>
        <Route path={`${url}/hobl-questionnaires`}>
          <ScrollToTop/>
          <HoblQuestionnaireList baseUrl={url}/>
        </Route>
        <Route path={`${url}/questionnaire/:questionnaireCode`}>
          <ScrollToTop/>
          <QuestionnairePage onlyShowResults={true} baseUrl={url}/>
        </Route>
        <Route path={`${url}/screening-result`}>
          <ScrollToTop/>
          <ResultPage baseUrl={url} hideRegistrationButton={true} showDownloadLink={true}
                      targetForDepressQuestionnaire={defaultClinicId ? "_self" : "_blank"}/>
        </Route>
        <Route path="*">
          <NotExistsPage withHomeLink={false} />
        </Route>
      </Switch>
    </div>
  )
}

