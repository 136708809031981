import {createStore, combineReducers, applyMiddleware} from 'redux';
import {questionnaireReducer} from "./reducers/questionnaireReducer";
import {personalReducer} from "./reducers/personalReducer";
import {profileModalReducer} from "./reducers/profileModalReducer";
import {IdentityType, ModalType} from "../utils/constants";
import {messageModalReducer} from "./reducers/messageModalReducer";
import {commonReducer} from "./reducers/commonReducer";
import thunk from 'redux-thunk';

const initialState = {
  messageModal: {
    isShown: false,
    title: '',
    text: ''
  },
  profileModal: {
    isShown: false,
    identityType:IdentityType.PHONE,
    modalType: ModalType.LOGIN,
    formErrors: {},
    isEmailSend: false,
    isDownloadResearch: false,
  },
  personal: {
    clinicId: null,
    ticket: null,
    token: null,
    profile: null
  },
  questionnaire: {
    questionnaireList: [],
    currentModuleIndex: 0,
    questionnaire: {
      modules: []
    },
    values: {},
    results: {}
  },
  common: {
    isLoading: 0
  }
}

let state = localStorage['redux-store']? Object.assign(initialState, JSON.parse(localStorage['redux-store'])) : initialState;

state.common.isLoading = 0;

const store = createStore(
  combineReducers({
    questionnaire: questionnaireReducer,
    personal: personalReducer,
    profileModal: profileModalReducer,
    messageModal: messageModalReducer,
    common: commonReducer,
  }),
  state,
  applyMiddleware(thunk),
);

store.subscribe(() => {
  localStorage['redux-store'] = JSON.stringify(store.getState());
})

export default store;