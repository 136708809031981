import {messageModal as Action} from '../constants';

export const messageModalReducer = (state = {}, action) => {
  switch (action.type) {
    case Action.SHOW_MESSAGE_MODAL: {
      return {...state, isShown: true, text: action.data.text, title: action.data.title, link: action.data.link ? action.data.link : null};
    }
    case Action.HIDE_MESSAGE_MODAL: {
      return {...state, isShown: false};
    }
    default:
      return state;
  }
}