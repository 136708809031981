import React, {useEffect, useState} from "react";
import {connect} from 'react-redux';
import {
  setOptions,
} from "../../store/actions/profileModalActions";
import {ModalType} from "../../utils/constants";
import {removeToken, removeProfile} from "../../store/actions/personalActions";
import {Link, useHistory} from "react-router-dom";
import {
  removeLastQuestionnaireResearch,
  removeQuestionnaireResearch,
  removeQuestionnaireResearchList
} from "../../store/actions/questionnaireActions";
import ApiService from "../../services/ApiService";

const CabinetHeaderProfile = ({profile, openProfileLoginModal, openProfileRegistrationModal, logout, isTokenExists}) => {
  const history = useHistory();

  const [isOpen, setIsOpen] = useState(false);
  const [isMenuShown, setIsMenuShown] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsOpen(false);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize)
  }, []);

  let timeOutId = null;

  const handleBlur = () => {
    timeOutId = setTimeout(() => setIsMenuShown(false));
  }

  const handleFocus = () => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
  }

  return (
    <>
      <div className="header__cabinet">
        {profile ? (
          <div className={"header-cabinet" + (isMenuShown ? " header-cabinet--opened" : "")}
               tabIndex={0}
               onFocus={handleFocus}
               onBlur={handleBlur}>
            <span className="header-cabinet__dropdown-link button button-link" onClick={() => setIsMenuShown(!isMenuShown)}>ID {profile?.id}</span>
            <ul className={"header-cabinet__dropdown" + (isMenuShown ? " header-cabinet__dropdown--opened" : "")}>
              <li className="header-cabinet__item header-cabinet__item--separator">
                <Link className="header-cabinet__link button button-link" to="/settings" onClick={() => setIsMenuShown(false)}>Настройки</Link>
              </li>
              <li className="header-cabinet__item">
                <button className="header-cabinet__link button button-link" onClick={() => {
                  setIsMenuShown(false);
                  history.push("#");
                  logout();
                }}>Выйти</button>
              </li>
            </ul>
          </div>
        ) : (
          <div>
          <button className="header__cabinet-link button button-link" onClick={openProfileRegistrationModal}>Регистрация</button>
          <button className="header__cabinet-link button button-link" onClick={openProfileLoginModal}>Вход</button>
          </div>
        )}
      </div>

      <button className={"header__mobile-menu hidden-xl" + (isOpen ? " header__mobile-menu--opened" : "")} onClick={() => setIsOpen(!isOpen)}/>

      <div className={"header__mobile-nav mobile-nav" + (isOpen ? " mobile-nav--opened" : "")}>
        <ul className="mobile-nav__list">
          {profile ? (
            <li className="mobile-nav__item mobile-nav__item--no-padding">
              <div className={"header-cabinet header-cabinet--embedded" + (isMenuShown ? " header-cabinet--opened" : "")}>
                <span className="header-cabinet__dropdown-link button button-link" onClick={() => setIsMenuShown(!isMenuShown)}>ID {profile?.id}</span>
                <ul className={"header-cabinet__dropdown" + (isMenuShown ? " header-cabinet__dropdown--opened" : "")}>
                  <li className="header-cabinet__item header-cabinet__item--separator">
                    <Link className="header-cabinet__link button button-link" to="/settings" onClick={() => setIsOpen(false)}>Настройки</Link>
                  </li>
                  <li className="header-cabinet__item">
                    <button className="header-cabinet__link button button-link" onClick={() => {
                      setIsOpen(false);
                      history.push("#");
                      logout();
                    }}>Выйти</button>
                  </li>
                </ul>
              </div>
            </li>
          ) : (
            <>
              <li className="mobile-nav__item">
                <button className="mobile-nav__link button button-link"
                        onClick={() => {openProfileRegistrationModal();setIsOpen(false);}}>Регистрация</button>
              </li>
              <li className="mobile-nav__item">
                <button className="mobile-nav__link button button-link"
                        onClick={() => {openProfileLoginModal();setIsOpen(false);}}>Войти</button>
              </li>
            </>
          )}

          {isTokenExists && (
          <li className="mobile-nav__item">
            <Link className="mobile-nav__link button button-link" to={"/personal"} onClick={() => setIsOpen(false)}>Личный кабинет</Link>
          </li>
          )}

          <li className="mobile-nav__item">
            <Link className="mobile-nav__link button button-link" to={"/questionnaires"} onClick={() => setIsOpen(false)}>Пройти обследование</Link>
          </li>

        </ul>

        <a className="mobile-nav__contact button button-link" href={window?.contacts?.phone?.href}>{window?.contacts?.phone?.title}</a>

        <ul className="mobile-nav__social social social--mini">

          <li className="social__item">
            <a className="social__link icon-vk" href={window?.contacts?.socials?.vk} />
          </li>

          <li className="social__item">
            <a className="social__link icon-instagram-2" href={window?.contacts?.socials?.instagram} />
          </li>

          <li className="social__item">
            <a className="social__link icon-facebook-2" href={window?.contacts?.socials?.facebook} />
          </li>

        </ul>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  profile: state?.personal?.profile,
  isTokenExists: state.personal?.token?.access_token
})

const mapDispatchToProps = dispatch => ({
  openProfileLoginModal() {
    dispatch(setOptions({
      modalType: ModalType.LOGIN,
      registrationTitle: null,
      isShown: true,
      text: null,
      isOnlyOneAction: false,
      isNotChooseIdentity: false,
      mainButtonText: null,
      additionalButton: null,
      isEmailSend: false,
      isDownloadResearch: false
    }))
  },
  openProfileRegistrationModal() {
    dispatch(setOptions({
      modalType: ModalType.REGISTRATION,
      registrationTitle: null,
      isShown: true,
      text: null,
      isOnlyOneAction: false,
      isNotChooseIdentity: false,
      mainButtonText: null,
      additionalButton: null,
      isEmailSend: false,
      isDownloadResearch: false
    }))
  },
  logout() {
    dispatch(removeToken());
    dispatch(removeProfile());
    dispatch(removeQuestionnaireResearch());
    dispatch(removeQuestionnaireResearchList());
    dispatch(removeLastQuestionnaireResearch());
    ApiService.init();
    window.location.hash = "/";
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CabinetHeaderProfile);