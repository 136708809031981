import React from 'react';
import {
  getColor,
  getIndividualRiskPhrase,
  getPhraseForDifferentBetweenIndividualRiskAndAgeGroupRisk
} from "../../utils/renderHelpers";

export const RiskScale = ({result}) => {
  if (result?.individualRisk > 100) {
    result.individualRisk = 100;
  }

  if (result?.individualRisk < 0) {
    result.individualRisk = 0;
  }

  let individualRiskColor = result?.individualRisk !== null ? getColor(result?.individualRisk) : "100,100,100";

  let phrases = [
    getPhraseForDifferentBetweenIndividualRiskAndAgeGroupRisk(result?.individualRisk, result?.ageGroupRisk),
    getIndividualRiskPhrase(result?.individualRisk)
  ]

  let highEdge = document.documentElement.clientWidth < 1200 ? 75 : 85;
  let lowEdge = document.documentElement.clientWidth < 1200 ? 25 : 15;

  return(
    <div className="main-result__risk-scale risk-scale result-section">
      <div className="container">
        <h2 className="risk-scale__heading result-section__heading">Шкала риска в вашей возрастной группе</h2>

        <p className="risk-scale__description">Уровень Вашего риска отмечен на шкале маркером</p>

        {result?.cerebralStroke || result?.myocardialInfarction || result?.diabetes ? (
          <p className="risk-scale__description">
            В связи с указанием на наличие {result?.diabetes ? "сахарного диабета" : ""}
            {result?.cerebralStroke ? ((result?.diabetes ? ", " : "") + "инсульта") : ""}
            {result?.myocardialInfarction ? ((result?.cerebralStroke || result?.diabetes ? ", " : "") + "инфаркта") : ""} в анамнезе ваш риск оценивается
            как очень высокий
          </p>
        ) : (
          <p className="risk-scale__description">
            В результате обследования установлено, что <b>Ваш суммарный индивидуальный риск</b> сердечно-сосудистых
            заболеваний, их осложнений, в том числе инсульта и инфаркта, {phrases[0]} и оценивается как {phrases[1]}
          </p>
        )}

        {result?.recommendations4 && (
          <p className="risk-scale__description">
            {result?.recommendations4}
          </p>
        )}

        {result?.requireEndocrinologistConclusion && (
          <p className="risk-scale__description">
            Для более точной оценки Вашего риска необходимо заключение эндокринолога о наличие сахарного диабета,
            в связи с чем оценка Вашего риска может быть изменена.
          </p>
        )}

        <div className="risk-scale__scale scale">
          <div className="scale__wrapper">
            <p className="scale__total-risk scale__message" style={{
              background: "rgb(" + individualRiskColor + ")",
              left: (result?.individualRisk < lowEdge ? "0%" : (result?.individualRisk > highEdge ? "" : result?.individualRisk + "%")),
              right: result?.individualRisk > highEdge ? "0%" : "",
              transform: (result?.individualRisk < lowEdge || result?.individualRisk > highEdge) ? "" : "translateX(-50%)"
            }}>
              Ваш суммарный индивидуальный риск
            </p>

            <div className="scale__total-risk-marker-wrapper scale-marker" style={{left: result?.individualRisk + "%"}}>
              <svg className="scale-marker__triangle" width="28" height="31" viewBox="0 0 28 31" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.5377 11.6244L12.8774 26.6244C12.4925 27.291 11.5303 27.291 11.1454 26.6244L2.48511 11.6244C2.10021 10.9577 2.58133 10.1244 3.35113 10.1244L20.6716 10.1244C21.4414 10.1244 21.9226 10.9577 21.5377 11.6244Z"
                  fill={"rgb(" + individualRiskColor + ")"} stroke="white" strokeWidth="2"/>
              </svg>

              <div className="scale__total-risk-marker scale-marker__marker"
                   style={{"background": "rgb(" + individualRiskColor + ")"}}/>
            </div>

            <div className="scale__inner">
              <div className="scale__item scale__item--low">низкий риск</div>
              <div className="scale__item scale__item--middle">средний риск</div>
              <div className="scale__item scale__item--high">высокий риск</div>
              <div className="scale__item scale__item--very-high">очень высокий</div>
            </div>

            <p className="scale__rick-in-age-group scale__message" style={{
              background: "rgb(" + result?.ageGroupRisk + ")",
              left: (result?.ageGroupRisk < lowEdge ? "0%" : (result?.ageGroupRisk > highEdge ? "" : result?.ageGroupRisk + "%")),
              right: result?.ageGroupRisk > highEdge ? "0%" : "",
              transform: (result?.ageGroupRisk < lowEdge || result?.ageGroupRisk > highEdge) ? "" : "translateX(-50%)"
            }}>
              Риск в Вашей
              возрастной группе
            </p>

            <div className="scale__rick-in-age-group-marker-wrapper scale-marker"
                 style={{left: result?.ageGroupRisk + "%"}}>
              <div className="scale__rick-in-age-group-marker scale-marker__marker"/>

              <svg className="scale-marker__triangle" width="28" height="29" viewBox="0 -2 28 20" fill="none"
                   xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M21.5377 17.3756L12.8774 2.37564C12.4925 1.70898 11.5303 1.70898 11.1454 2.37564L2.48511 17.3756C2.10021 18.0423 2.58133 18.8756 3.35113 18.8756L20.6716 18.8756C21.4414 18.8756 21.9226 18.0423 21.5377 17.3756Z"
                  fill="#C7C7C7" stroke="white" strokeWidth="2"/>
              </svg>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}