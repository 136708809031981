import React from "react";
import {PointedResultScale} from "../PointedResultScale/PointedResultScale";
import {PointedInterpretativeResultScale} from "../PointedInterpretativeResultScale/PointedInterpretativeResultScale";
import {getColorResult} from "../../utils/renderHelpers";

export const PointedQuestionnaireResult = ({results}) => {
  return (
    <div>
      <div className="result-section">
        <h2 className={"tabs__heading result-section__heading" + (results?.length > 1 ? " result-section__heading--no-padding-bottom" : "")}>Ваши результаты</h2>

        {results && results?.map((item, index) => (
          item?.testInterpretation?.length && item.score !== null && (
            <div key={item.name + index}>
              {item.name && results.length > 1 ? (
              <h2 className="tabs__heading result-section__heading result-section__heading--mini">{item.name}</h2>
                ):null}
              <PointedResultScale key={item.description} results={item.testInterpretation} score={item.score}/>
            </div>
          )
        ))}
      </div>

      <div className="result-section">
        <h2 className={"result-section__heading--no-padding-top result-section__heading--mobile result-section__heading" + (results.length > 1 ? " result-section__heading--no-padding-bottom" : "")}>Интерпретация теста:</h2>

        {results && results?.map((item, index) => (
          item?.testInterpretation?.length && (
            <div key={item.name + index}>
              {item.name && results.length > 1 ? (
              <h2 className="tabs__heading result-section__heading result-section__heading--mini">{item.name}</h2>
              ):null}
              <PointedInterpretativeResultScale key={item.description} results={item.testInterpretation} />
            </div>
          )
        ))}
      </div>

      <div className="result-section">
        <h2 className="result-section__heading result-section__heading--no-padding-top result-section__heading--no-padding-bottom">Ваши результаты:</h2>

        {results && results?.map(item => (
          <div key={item.description} className="result-section__main-result" style={{color: item?.testInterpretation ? getColorResult(item.testInterpretation, item.score) : null}}>{item.description}</div>
        ))}

      </div>
    </div>
  )
}