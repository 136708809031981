import React from "react";

export const TermsOfUse = () => (
  <div className="main-container__inner depression-questionnaire">
    <div className="container">

      <h1 className="main-container__header depression-questionnaire__header heading">Пользовательское соглашение сайта https://escreen.online/</h1>

      <style>{".c4, .c8 {\n" +
      "          font-weight: bold;\n" +
      "        }"}
      </style>
      <div className="main-container__description main-container__description--no-padding-bottom">
        <p className="c1"><span className="c3">Общество с ограниченной ответственностью «Медицинская технологическая компания» ОГРН 1187746199882, ИНН 7731399263, КПП 773101001, юридический адрес: 121108, город Москва, ул. Пивченкова, д. 2, кв. 4 (далее – ООО «МТК»), в лице генерального директора Беляева Евгения Сергеевича, действующего на основании Устава, адресует настоящую оферту о заключении Пользовательского соглашения, определяющего условия использования сайта </span><span
          className="c11"><a className="c10"
                             href="https://www.google.com/url?q=https://escreen.online/&amp;sa=D&amp;source=editors&amp;ust=1622317605326000&amp;usg=AOvVaw1LM96A2-Nx9gx_h3BCdvvL">https://escreen.online/</a></span><span
          className="c0">&nbsp;(далее – «Оферта») любому физическому лицу (далее – «Пользователь»), которое принимает условия Пользовательского соглашения путем его полного и безоговорочного акцепта согласно ст. 428 ГК РФ. </span>
        </p><p className="c1"><span className="c0">До начала использования Сайта, Пользователь обязан ознакомиться с текстом Пользовательского соглашения и принять его. В случае несогласия Пользователя с какими-либо условиями Пользовательского соглашения, Пользователь не имеет права использовать Сайт и обязан его покинуть. </span>
        </p><p className="c2"><span className="c4">Основные термины, применяемые в Пользовательском соглашении</span>
        </p><p className="c2"><span className="c8">Сайт</span><span
          className="c3">&nbsp;</span><span className="c8">- </span><span className="c3">совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по адресу: </span><span
          className="c11"><a className="c10"
                             href="https://www.google.com/url?q=https://escreen.online/&amp;sa=D&amp;source=editors&amp;ust=1622317605328000&amp;usg=AOvVaw2jpnknSjXUI7FrYj_9aUCk">https://escreen.online/</a></span>
        </p><p className="c2"><span className="c8">Контент</span><span className="c3">&nbsp;</span><span
          className="c8">-</span><span className="c0">&nbsp;специально подобранные и расположенные определенным образом материалы (тексты, изображения, фотографии, и т.д.), которые могут быть использованы с помощью компьютерной программы (компьютерного кода), являющегося элементом Сайта (постановление Президиума ВАС № 255/08 от 22.04.2008).</span>
        </p><p className="c2"><span className="c8">Пользователь</span><span className="c3">&nbsp;</span><span
          className="c8">-</span><span className="c0">&nbsp;физическое лицо, имеющее доступ к Сайту посредством сети Интернет и посетившее Сайт.</span>
        </p><p className="c2"><span className="c8">ПО</span><span className="c3">&nbsp;</span><span
          className="c8">-</span><span className="c0">&nbsp;программное обеспечение, размещенное на сайте, а также любая из функций Сайта, независимо от того, предоставляются ли такие функции Пользователю за плату или нет.</span>
        </p><p className="c2"><span className="c8">ПО с открытым доступом -</span><span className="c0">&nbsp;программное обеспечение и иные материалы, для просмотра и использования которых Пользователю не требуется регистрация на Сайте.</span>
        </p><p className="c2"><span className="c8">ПО с ограниченным доступом -</span><span
          className="c0">&nbsp;программное обеспечение и иные материалы, для просмотра и использования которых Пользователю требуется предварительная регистрация на Сайте.</span>
        </p><p className="c2"><span className="c8">Личный кабинет -</span><span className="c0">&nbsp;часть Сайта, доступ к которой имеет Пользователь, прошедший предварительную регистрацию путем предоставления номера телефона и/или электронной почты.</span>
        </p><p className="c2"><span
          className="c4">Общие положения</span></p><p className="c2 c5"><span className="c0"></span></p><p
          className="c1"><span className="c0">Сайт создан в целях улучшения коммуникации между пациентами и врачами, прохождения скрининговых опросов, позволяющих понять свое самочувствие, выявить риски ряда определенных заболеваний и сформулировать вопросы к лечащему врачу для получения последующей профессиональной консультации.</span>
        </p><p className="c1"><span className="c0">Сайт содержит ПО «Эксперт Скрининг», в основу которого включены международные опросники, выбранные профессиональным сообществом за их точность и чувствительность в формулировках о состоянии здоровья. «Эксперт Скрининг» позволяет выявить индивидуальные риски таких заболеваний, как: инфаркт, ишемическая болезнь сердца, стенокардия напряжения, гастроэзофагальный рефлюкс, хроническая обструктивная болезнь легких, бронхиальная астма, сахарный диабет, артериальная гипертензия, дислепидемия, а также оценить общие факторы риска образа жизни на основании данных, представленных Пользователем в ходе прохождения опросов.</span>
        </p><p className="c1"><span className="c0">Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации.</span>
        </p><p className="c1"><span className="c0">Пользователь вправе использовать материалы Сайта исключительно для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности.</span>
        </p><p className="c1"><span className="c0">Все указания, сформированные по итогам прохождения опросов имеют строго рекомендательный характер. Пользователь ознакомлен с тем, что полученную с помощью Сайта информацию нельзя использовать для самодиагностики и самолечения. Для постановки диагноза и назначения соответствующего лечения необходимо обращаться к лечащему врачу.</span>
        </p><p className="c2"><span className="c4">Регистрация на Сайте и личный кабинет Пользователя</span>
        </p><p className="c1"><span className="c0">В целях получения доступа к прохождению опросов, и иным функциональным возможностям Сайта может потребоваться регистрация Пользователя путем указания номера мобильного телефона и/или электронной почты, после чего Пользователь получит доступ в Личный кабинет с возможностью просмотра всех результатов пройденных вопросов, которые при желании, могут быть предоставлены самим Пользователем непосредственно лечащему врачу в медико-профилактических целях, в целях установления медицинского диагноза, оказания медицинских и медико-социальных услуг.</span>
        </p><p className="c1"><span className="c0">Пользователь обязан обеспечить сохранность и конфиденциальность информации, для доступа в Личный кабинет, не передавать данную информацию третьим лицам. Пользователь вправе направить в ООО «МТК» запрос на удаление своего Личного кабинета, что повлечет за собой удаление результатов всех пройденных опросов.</span>
        </p><p className="c1"><span className="c0">ООО «МТК» вправе удалить Личный кабинет Пользователя и прекратить для него доступ к Сайту без объяснения причин, в том числе в случае нарушения Пользователем условий Пользовательского соглашения или политики конфиденциальности и обработки персональных данных.</span>
        </p><p className="c2"><span className="c4">Использование Сайта</span>
        </p><p className="c1"><span className="c0">Посещая Сайт без регистрации, Пользователь вправе ознакамливаться со всеми его материалами, находящимся в открытом доступе.</span>
        </p><p className="c1"><span
          className="c0">После принятия Пользовательского соглашения, Пользователь вправе:</span></p><p className="c2">
          <span className="c0">- просматривать Сайт и использовать все доступные функции Сайта;</span></p><p
          className="c2"><span
          className="c0">- использовать ПО с открытым и закрытым доступом, размещенное на Сайте; </span></p><p
          className="c1"><span className="c0">Пользователь уведомлен о том, что часть ПО, находящаяся в закрытом доступе может быть доступна за определенную плату, устанавливаемую по решению ООО «МТК», которое вправе по своему усмотрению в одностороннем порядке определять перечень платных услуг, устанавливать плату и изменять ее, уведомляя о Пользователя об изменениях путем публикации соответствующей информации на Сайте. В случае несогласия с изменением стоимости, Пользователь вправе в одностороннем порядке отказаться от использования платных услуг или исполнения настоящего Соглашения в целом, заранее уведомив ООО «МТК». Пользователь обязуется оплачивать платные услуги способами и в соответствии с инструкциями, указанными на Сайте. Оплата может быть осуществлена при помощи защищенных сервисов платежей третьих лиц (платежных агентов), обеспечивающих проведение платежа без передачи платежной информации ООО «МТК». Порядок использования таких сервисов регулируется правилами, доступными к ознакомлению во время проведения платежа. </span>
        </p><p className="c1"><span className="c0">ООО «МТК» вправе в любое время в одностороннем порядке изменять контент Сайта, а также изменять условия Пользовательского соглашения и порядок предоставления доступа к Сайту. Такие изменения вступают в силу с момента размещения новой версии Соглашения на Сайте. При несогласии Пользователя с внесенными изменениями он обязан прекратить использование и покинуть Сайт.</span>
        </p><p className="c1"><span className="c0">При цитировании материалов Сайта, включая охраняемые авторские произведения, ссылка на Сайт обязательна (п.п. 1 п. 1 ст. 1274 ГК РФ).</span>
        </p><p className="c1"><span className="c0">Пользователь соглашается с тем, что ООО «МТК» не несет какой-либо ответственности и не имеет каких-либо обязательств в связи с рекламой, которая может быть размещена на Сайте.</span>
        </p><p className="c1"><span className="c0">ООО «МТК» не несет ответственности и не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими потерями, или убытками, связанными с любым содержанием Сайта, товарами или услугами, доступными на или полученными через внешние сайты или ресурсы либо иные контакты Пользователя, в которые он вступил, используя размещенную на Сайте информацию или ссылки на внешние ресурсы.</span>
        </p><p className="c1 c6"><span className="c0">Вся информация на Сайте размещается «как есть» («as is») с возможными ошибками, без гарантий любого вида, прямо выраженных или подразумеваемых. Сайт, его дизайн, изображения, фотографии, текст, графика, логотипы, а также всё программное обеспечение Сайта защищены юридически. В частности, авторские права на содержание сайта, в том числе ПО с открытым и закрытым доступом, а также дизайн Сайта.</span>
        </p><p className="c1 c6"><span className="c0">Пользователи Сайта не приобретают прав ни на Сайт, ни на Контент, ни на содержащуюся на Сайте информацию и данные.</span>
        </p><p className="c1 c6"><span className="c0">Фотографии, доступные на Сайте, принадлежат ООО «МТК», либо лицам, предоставившим соответствующие права на их использование, вследствие чего эти объекты могут использоваться третьими лицами исключительно в некоммерческих целях, с разрешения правообладателя и с обязательным указанием авторских прав.</span>
        </p><p className="c1"><span className="c0">В отдельных случаях ООО «МТК» вправе отсылать Пользователям сообщения на номер мобильного телефона и/или электронной почты, указанные при регистрации с целью информирования о новых функциях и возможностях, предоставляемых Пользователям Сайта.</span>
        </p><p className="c2 c6"><span className="c4">Заключительные положения</span>
        </p><p className="c1"><span className="c0">Правила соблюдения конфиденциальности и условия обработки персональных данных определены политикой конфиденциальности и обработки персональных данных, размещенной на Сайте по адресу __________________________</span>
        </p><p className="c1"><span className="c0">Заполнение любых форм на Сайте, предусматривающих ввод контактных данных и иной информации, означает безоговорочное согласие с политикой конфиденциальности и обработки персональных данных и Пользовательским соглашением.</span>
        </p><p className="c1"><span className="c0">ООО «МТК» не имеет обязательств перед Пользователем по предоставлению каких-либо консультаций и разъяснений по вопросам использования Сайта и размещенного на нем ПО с открытым и закрытым доступом, не обязано устранять проблемы технического характера, также, как и непосредственно решать такие проблемы, которые могут повлечь за собой невозможность использования Сайта у Пользователя. </span>
        </p><p className="c1"><span className="c0">Настоящее Соглашение регулируется нормами законодательства Российской Федерации, и по своей сути признается договором присоединения (ст. 428 ГК) согласно судебной практики (Постановление Арбитражного суда Волго-Вятского округа от 27.02.2017 № Ф01-6475/2016 по делу № А11-2441/2016, Постановление Арбитражного суда Волго-Вятского округа от 05.10.2015 № Ф01-3884/2015 по делу № А39-3553/2014), Постановление Арбитражного суда Уральского округа от 08.04.2015 № Ф09-1292/15 по делу № А60-35501/2014).</span>
        </p><p className="c1" id="h.gjdgxs"><span className="c0">Все споры и разногласия или требования, вытекающие из Пользовательского соглашения или в связи с ним, в том числе касающиеся его исполнения, нарушения, прекращения или недействительности, подлежат разрешению в Черемушинском районном суде города Москвы.</span>
        </p><p className="c1"><span className="c0">Признание судом какого-либо положения настоящего Соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности или неисполнимости иных положений Соглашения.</span>
        </p><p className="c1"><span className="c0">В случае нарушения Пользователем условий Пользовательского соглашения, а равно и Политики конфиденциальности и обработки персональных данных, повлекшее за собой возникновение убытков у ООО «МТК», последнее вправе взыскать с Пользователя возмещение таких убытков в полном объеме в соответствии со ст. 15 ГК РФ.</span>
        </p>
      </div>

    </div>
  </div>
)