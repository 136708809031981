import React from 'react';

import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'

class DropdownQuestionnaireGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    }
  }

  handleClick = () => {
    this.setState(state => ({
      isOpen: !state.isOpen
    }))
  }

  render() {
    let {items, currentIndex} = this.props;

    return (
      <div className="questionnaire-groups questionnaire-groups--mobile">

        <div className="questionnaire-groups__list">

          <div className={"questionnaire-groups__item" + (!this.state.isOpen ? " questionnaire-groups__item--closed" : "")}>
            <span className={"questionnaire-groups__link questionnaire-groups__link--caret" + (this.state.isOpen ? " questionnaire-groups__link--opened" : "") + (currentIndex === 0 ? " questionnaire-groups__link--current":"")} onClick={this.handleClick}>{items?.[0]?.title}</span>
          </div>

          <SlideDown>
            {this.state.isOpen ? (
              items && items.map((item, index) => (
                index !== 0 && (
                <div key={item?.code} className="questionnaire-groups__item">
                  <span className={"questionnaire-groups__link" + (currentIndex === index + 1 ? " questionnaire-groups__link--current":"")}>{item?.title}</span>
                </div>)
              ))
              ) : null}
          </SlideDown>

        </div>

      </div>
    )
  }
}

export default DropdownQuestionnaireGroup;