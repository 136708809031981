import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {Field} from '../';
import {
  setValue,
  toNextModule,
  toPrevModule,
} from '../../store/actions/questionnaireActions';
import {bindActionCreators} from "redux";
import {
  fetchQuestionnaireData,
  sendQuestionnaireResultData
} from "../../store/actionCreators/questionnaireActionCreator";
import DropdownQuestionnaireGroup from "../DropdownQuestionnaireGroup/DropdownQuestionnaireGroup";
import {QuestionnaireType} from "../../utils/constants";

function useForceUpdate(){
  const [value, setValue] = useState(0);
  return () => setValue(value => value + 1);
}

const Questionnaire = ({title, modules, currentModuleIndex, values, onAnswered, code, onClickToPrevModule,
                         onClickToNextModule, onSendResults, fetchQuestionnaireData, appointmentWithADoctor,
                         onlyShowResults = false, baseUrl = ""}) => {
  values = values && code ? values[code] : {};

  const progressLine = useRef(null);
  const forceUpdate = useForceUpdate();

  let answeredCount = 0;
  let total = 0;

  if (modules) {
    modules.forEach(module => {
      module?.questions?.forEach(question => {
        if (question.available && !question.isOptional) {
          total++;

          if (values[question?.code]) {
            answeredCount++;
          }
        }
      })
    })
  }

  let progress = Math.round(answeredCount / total * 100);

  useEffect(() => {
    let boundingClientRect = progressLine.current.getBoundingClientRect();
    let offset = window.innerWidth >= 1230 ? 120 : 90;

    if (boundingClientRect) {
      let y = currentModuleIndex !== 0 ? boundingClientRect.top + window.pageYOffset - offset : 0

      window.scrollTo(0, y);
    }
  }, [currentModuleIndex]);

  return (
    <div className="main-container__inner questionnaire">
      <div className="container">

        <div className="questionnaire__flex-wrapper">
          {code === QuestionnaireType.CARDIOVASCULAR_DISEASES ? (
            <p className="questionnaire__time">Обследование займет 5-10 минут</p>
          ) : (
            <p className="questionnaire__time">Тест займет 2-5 минут</p>
          )}

          {code === QuestionnaireType.CARDIOVASCULAR_DISEASES ? (
            <h1 className="main-container__header questionnaire__header heading">{title}</h1>
          ) : (
            <h1 className="main-container__header questionnaire__header heading">Тест «{title}»</h1>
          )}
        </div>

        <div className="main-container__description main-container__description--no-padding-bottom">
          {window.questionnaires?.[code]?.preamble ? (
            <div dangerouslySetInnerHTML={{__html: window.questionnaires?.[code]?.preamble}}></div>
          ) : null}
        </div>

        <div className="questionnaire__progress-line progress-line">
          <span className="progress-line__line" style={{"width": progress +"%"}} ref={progressLine}></span>
          <span className="progress-line__text">пройдено {progress}%</span>
        </div>

        {modules && (
        <div className="questionnaire__columns row">
          {modules && modules.length > 1 && (
            <div className="questionnaire__groups questionnaire__groups--mobile col">
              <DropdownQuestionnaireGroup items={modules} currentIndex={currentModuleIndex}/>
            </div>
          )}
          {modules && modules.length > 1 && (
          <div className="questionnaire__groups col">
            <div className="questionnaire-groups">

              <ul className="questionnaire-groups__list">

                {modules && modules.map((item, index) => (
                  <li key={item?.code} className="questionnaire-groups__item">
                    <span className={"questionnaire-groups__link" + (currentModuleIndex === index ? " questionnaire-groups__link--current":"")
                    + (item.isAnswered ? " questionnaire-groups__link--passed":"")}>{item.title}</span>
                  </li>
                ))}

              </ul>

              <div className="questionnaire-groups__button-wrapper">
                <button className="questionnaire-groups__button questionnaire-groups__button--prev button button-secondary" type="button"
                        disabled={ currentModuleIndex === 0 } onClick={onClickToPrevModule} />
                {currentModuleIndex + 1 !== modules.length && (
                  <button className="questionnaire-groups__button questionnaire-groups__button--next button button-secondary" type="button"
                          disabled={ !modules[currentModuleIndex]?.isAnswered}
                          onClick={() => {
                            if (code === QuestionnaireType.CARDIOVASCULAR_DISEASES) {
                              window?.ym(85118782, 'reachGoal', 'module' + (currentModuleIndex + 1));
                            }

                            onClickToNextModule();
                          }} />
                )}
                {currentModuleIndex + 1 === modules.length && (
                  <button className="questionnaire-groups__button questionnaire-groups__button--next button button-secondary" type="button"
                          disabled={ !modules[currentModuleIndex]?.isAnswered }
                          onClick={() => {
                            if (code === QuestionnaireType.CARDIOVASCULAR_DISEASES) {
                              window?.ym(85118782,'reachGoal','all_modules')
                            }

                            onSendResults({appointmentWithADoctor, onlyShowResults, baseUrl})
                          }} />
                )}
              </div>

            </div>
          </div>)}

          <div className="questionnaire__questions col">
            {modules[currentModuleIndex] && modules[currentModuleIndex]?.questions.map((item, index) => (
              item?.available && (
                <div key={item?.code} className="questionnaire__question question">
                  <h2 className="question__title">{item.title}</h2>

                  <div className="question__answers">
                    <Field type={item.type} name={item.code} options={item.options}
                           onChange={(value) => {onAnswered(item.code, value);forceUpdate()}}
                           defaultValue={values[item.code]} format={item.format}/>
                  </div>

                </div>)
            ))}

            <div className="questionnaire__button-wrapper">
              {modules && modules.length > 1 && (
              <button className="questionnaire__button button button-primary button-primary--mini" type="button"
                      disabled={ currentModuleIndex === 0 } onClick={onClickToPrevModule}>Назад</button>
              )}
              {currentModuleIndex + 1 !== modules.length && (
                <button className="questionnaire__button button button-primary button-primary--mini button-primary--wide"
                        type="button" disabled={ !modules[currentModuleIndex]?.isAnswered }
                        onClick={() => {
                          if (code === QuestionnaireType.CARDIOVASCULAR_DISEASES) {
                            window?.ym(85118782, 'reachGoal', 'module' + (currentModuleIndex + 1));
                          }

                          onClickToNextModule();
                        }}>Вперед</button>
              )}
              {currentModuleIndex + 1 === modules.length && (
                <button className="questionnaire__button button button-primary button-primary--mini button-primary--wide"
                        type="button" disabled={ !modules[currentModuleIndex]?.isAnswered }
                        onClick={() => {
                          if (code === QuestionnaireType.CARDIOVASCULAR_DISEASES) {
                            window?.ym(85118782,'reachGoal','all_modules')
                          }

                          onSendResults({appointmentWithADoctor, onlyShowResults, baseUrl})
                        }}>Вперед</button>
              )}
            </div>

          </div>

        </div>)}
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  ...state.questionnaire?.questionnaire,
  currentModuleIndex: state.questionnaire?.currentModuleIndex === undefined ? 0 : state.questionnaire?.currentModuleIndex,
  values: state.questionnaire?.values,
})

const mapDispatchToProps = dispatch => ({
  onSendResults: (options) => bindActionCreators(
    () => (sendQuestionnaireResultData(options)),
    dispatch)(),
  onAnswered(id, value) {
    dispatch(setValue(id, value))
  },
  onClickToPrevModule() {
    dispatch(toPrevModule())
  },
  onClickToNextModule() {
    dispatch(toNextModule())
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Questionnaire);
