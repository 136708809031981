const IdentityType = {
  EMAIL: "EMAIL",
  PHONE: "PHONE"
}

const ModalType = {
  LOGIN: "LOGIN",
  REGISTRATION: "REGISTRATION",
  VERIFY_REGISTRATION: "VERIFY_REGISTRATION",
  PASSWORD_RECOVERY: "PASSWORD_RECOVERY",
  RESET_PASSWORD: "RESET_PASSWORD",
}

const QuestionnaireType = {
  CARDIOVASCULAR_DISEASES: "cardiovascular_diseases",
  HOBL: "hobl",
  SEVERITY_OF_HOBL: "severity_of_hobl",
  ASTHMA: "asthma",
  GASTROESOPHAGEAL_REFLUX: "gastroesophageal_reflux",
  DIABETES: "diabetes",
  DEPRESSION: "depression",
  DEPRESSION_ANXIENTY: "depression_anxiety",
  DEPRESSION_EFFECTIVENESS_OF_ANTIDEPRESSANTS: "depression_effectiveness_of_antidepressants",
}

export {IdentityType, ModalType, QuestionnaireType};