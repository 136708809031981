import React from "react";
import {bindActionCreators} from "redux";
import {fetchQuestionnaireListData} from "../../store/actionCreators/questionnaireActionCreator";
import {connect} from "react-redux";
import {QuestionnaireType} from "../../utils/constants";

class QuestionnaireList extends React.Component {
  componentDidMount() {
    this.props.fetchQuestionnaireList();
  }

  render() {
    const questionnaireList = this.props.questionnaireList || [];
    const baseUrl = this.props.baseUrl || "";
    const page = this.props.page || "cabinet";
    const openInNewWindow = !!this.props.openInNewWindow;

    return (
      <ul className="prevention__questionnaire-list questionnaire-list row">

        <li className="questionnaire-list__item questionnaire-list__item--full col">

          <div className="prevention__questionnaire questionnaire-card--with-button questionnaire-card questionnaire-card--main icon-heart">
            <div className="questionnaire-card__text-wrapper">
              <h3 className="questionnaire-card__heading">Управление индивидуальным риском сердечно-сосудистых заболеваний</h3>
              <p className="questionnaire-card__notice">(Технология прошла клиническую апробацию)</p>
            </div>

            <span tabIndex="-1" className="questionnaire-card__info-link" />
            <p className="questionnaire-card__info">В основу оценки риска положены современные рекомендации Всемирной организации здравоохранения, Всероссийского научного и Европейского общества кардиологов</p>

            <div className="questionnaire-card__button-wrapper">
              <a className="questionnaire-card__button button button-primary" href={
                "/" + page + "#" + baseUrl + "/questionnaire/" + questionnaireList.find(item => item.code === QuestionnaireType.CARDIOVASCULAR_DISEASES)?.code
              } target={openInNewWindow ? '_blank' : '_self'}>Пройти обследование</a>
            </div>
          </div>

        </li>

        <li className="questionnaire-list__item col">

          <div className="questionnaire-card questionnaire-card--with-button icon-depression" >
            <h4 className="questionnaire-card__heading">Депрессия</h4>
            <span tabIndex="-1" className="questionnaire-card__info-link" />
            <p className="questionnaire-card__info">Клинические проявления депрессии повышают риск метаболических нарушений, диабета второго типа, и риск сердечно-сосудистых заболеваний.</p>

            <div className="questionnaire-card__button-wrapper">
              <a className="questionnaire-card__button button button-primary button-primary--mini"
                 href={"/" + page + "#" + baseUrl + "/depression-questionnaires"}
                 target={openInNewWindow ? '_blank' : '_self'}>Пройти скрининг</a>
            </div>
          </div>

        </li>

        <li className="questionnaire-list__item col">

          <div className="questionnaire-card questionnaire-card--with-button icon-diabetes">
            <h4 className="questionnaire-card__heading">Сахарный диабет II типа</h4>
            <span tabIndex="-1" className="questionnaire-card__info-link" />
            <p className="questionnaire-card__info">Клиническим проявлениям сахарного диабета 2 типа обычно предшествуют эпизоды повышения сахара крови и предиабет.</p>

            <div className="questionnaire-card__button-wrapper">
              <a className="questionnaire-card__button button button-primary button-primary--mini"
                 target={openInNewWindow ? '_blank' : '_self'}
                 href={"/" + page + "#" + baseUrl + "/questionnaire/" + questionnaireList.find(item => item.code === QuestionnaireType.DIABETES)?.code}>Пройти скрининг</a>
            </div>
          </div>

        </li>

        <li className="questionnaire-list__item col">

          <div className="questionnaire-card questionnaire-card--with-button icon-lungs">
            <h4 className="questionnaire-card__heading">Бронхолегочные заболевания</h4>
            <span tabIndex="-1" className="questionnaire-card__info-link" />
            <p className="questionnaire-card__info">Основными симптомами ХОБЛ являются постоянный кашель с отделением мокроты и одышка при физической нагрузке.</p>

            <div className="questionnaire-card__button-wrapper">
              <a className="questionnaire-card__button button button-primary button-primary--mini"
                 target={openInNewWindow ? '_blank' : '_self'}
                 href={"/" + page + "#" + baseUrl + "/hobl-questionnaires"}>Пройти скрининг</a>
            </div>
          </div>

        </li>

        <li className="questionnaire-list__item col">

          <div className="questionnaire-card questionnaire-card--with-button icon-reflux">
            <h4 className="questionnaire-card__heading">Гастроэзофагальная рефлюксная болезнь</h4>
            <span tabIndex="-1" className="questionnaire-card__info-link" />
            <p className="questionnaire-card__info">Основной симптом гастроэзофагеальной рефлюксной болезни&nbsp;– это изжога.</p>

            <div className="questionnaire-card__button-wrapper">
              <a className="questionnaire-card__button button button-primary button-primary--mini"
                 target={openInNewWindow ? '_blank' : '_self'}
                 href={"/" + page + "#" + baseUrl + "/questionnaire/" + questionnaireList.find(item => item.code === QuestionnaireType.GASTROESOPHAGEAL_REFLUX)?.code}>Пройти скрининг</a>
            </div>
          </div>

        </li>

      </ul>
    )
  }
}

const mapStateToProps = (state) => ({
  questionnaireList: state.questionnaire.questionnaireList
})

const mapDispatchToProps = (dispatch) => ({
  fetchQuestionnaireList: bindActionCreators(fetchQuestionnaireListData, dispatch),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QuestionnaireList);
