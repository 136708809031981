import React from "react";

export const Faq = () => (
  <div className="main-container__inner depression-questionnaire">
    <div className="container">

      <h1 className="main-container__header depression-questionnaire__header heading">Часто задаваемые вопросы</h1>

      <div className="main-container__description main-container__description--no-padding-bottom text-page">
        <h3>1. Зачем мне проходить обследование на Вашем портале?</h3>
        <p>
          Профилактика хронических неинфекционных заболеваний (НИЗ) – одна из важнейших
          составляющих долгой и здоровой жизни. На нашем портале Вы сможете быстро и удобно
          пройти скрининг с целью профилактики ряда заболеваний и факторов риска.
          Ранняя диагностика позволит не только своевременно выявить возможные заболевания,
          но и минимизировать дальнейший ущерб от их развития. Коррекция образа жизни и
          управление факторами риска способны существенно снизить риск развития заболевания и
          его влияния на Ваше здоровье. Управляйте своим здоровьем!
        </p>

        <h3>2. Как мне пройти обследование?</h3>
        <p>
          Для прохождения обследования выберите интересующий Вас раздел и следуйте
          инструкциям. Все разделы на нашем портале обладают удобным и интуитивно понятным
          интерфейсом. Вы можете ознакомиться с результатами обследований без регистрации
          или получить результаты на почту и сохранить их в личном кабинете. Пройти
          обследование на нашем портале Вы можете как с ПК, так и со смартфона.
        </p>

        <h3>3. Какие обследования я могу пройти на вашем портале?</h3>
        <p>
          На нашем портале Вы можете пройти скрининг следующих заболеваний и факторов риска:
          <ul>
            <li>Депрессия</li>
            <li>Тревожность</li>
            <li>Сахарный диабет</li>
            <li>Хроническая обструктивная болезнь легких (ХОБЛ)</li>
            <li>Гастроэзофагальная рефлюксная болезнь</li>
            <li>Вероятность инсульта</li>
            <li>Вероятность инфаркта</li>
            <li>Ишемическая болезнь сердца (ИБС)</li>
            <li>Стенокардия напряжения</li>
            <li>Артериальная гипертензия</li>
            <li>Дислипидемия</li>
            <li>Течение бронхиальной астмы</li>
          </ul>
        </p>

        <h3>4. Что я получу в результате обследования?</h3>
        <p>
          Пройдя обследование на нашем портале, Вы получите первичную картину состояния своего
          здоровья по 12 заболеваниям. Также Вы сможете получить персонализированные рекомендации
          по управлению индивидуальным сердечно-сосудистым риском, основанные на новейших
          материалах и руководствах ведущих международных медицинских сообществ и клинических
          рекомендациях Всемирной организации здравоохранения (ВОЗ) и Минздрава РФ.
        </p>

        <h3>5. Почему я должен доверять результатам обследования на вашем портале?</h3>
        <p>
          Платформа «Эксперт Скрининг» создана при участии представителей профессионального
          медицинского сообщества и включает международные стандартизированные методы скрининга.
          В основу положены материалы и рекомендации ведущих научных медицинских организаций и
          сообществ в области терапии, кардиологии, неврологи, пульмонологии, эндокринологии и
          диетологии, соответствующие стандартам ВОЗ.
        </p>

        <h3>6. В чем уникальность «Эксперт Скрининг»?</h3>
        <p>
          «Эксперт «Скрининг» содержит уникальную технологию оценки и управления риском сердечно-
          сосудистых заболеваний (ССЗ), прошедшую клиническую апробацию на базе ФГУ
          «Государственный научно-исследовательский центр профилактический медицины» и
          рекомендованную Ученым советом научно-исследовательского центра к внедрению в
          клиническую практику. Технология содержит инновационный алгоритм оценки и управления
          индивидуальным риском ССЗ, разработанный с использованием базы данных
          эпидемиологического исследования, наиболее репрезентативной для популяции России и стран
          СНГ. В результате обследования Вы узнаете:
          <ul>
            <li>Ваш риск ССЗ относительно риска Вашей возрастной группы.</li>
            <li>Степень влияния факторов риска на индивидуальный суммарный риск ССЗ.</li>
            <li>Целевые показатели артериального давления, частоты сердечных сокращений, индекса
      массы тела, важных биохимических параметров.</li>
            <li>Персонализированные рекомендации по управлению сердечно-сосудистым риском.</li>
            <li>Маршрутизацию дальнейшего обследования (рекомендуемые анализы, клинические
  обследования и консультации специалистов).</li>
          </ul>
          Помимо этого мы разработали для Вас возможность скрининга ряда заболеваний согласно
          международным профессиональным стандартам с наглядной визуализацией результатов. Вы
          можете не только ознакомиться с результатами онлайн, но также сохранить их в личном кабинете
          или на любом устройстве в формате pdf, открыть или распечатать и обсудить со своим лечащим
          врачом.
        </p>

        <h3>7. Что делать если я не знаю ответа на вопрос?</h3>
        <p>
          Часть вопросов являются обязательными. В случае, если Вы не знаете ответа на вопрос в данный
          момент (например, показатель артериального давления), то всегда сможете вернуться к
          заполнению анкеты, создав личный кабинет, в котором сохранятся текущие ответы.
        </p>

        <h3>8. Что делать после получения результатов обследования?</h3>
        <p>
          После получения результатов обследования рекомендуем Вам обсудить их с Вашим лечащим
          врачом или записаться на прием к медицинским специалистам для подбора соответствующего
          лечения в случае необходимости. Обращаем Ваше внимание, что результаты скрининга на нашем
          портале не являются медицинской консультацией, не могут быть использованы для самолечения
          или самостоятельной постановки диагноза.
        </p>

      </div>

    </div>
  </div>
)