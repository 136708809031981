import {personal as Action} from '../constants';

export const setIsRefreshNeeded = (isRefreshNeeded) => ({
  type: Action.SET_IS_REFRESH_NEEDED,
  data: isRefreshNeeded
})

export const setProfile = (profile) => ({
  type: Action.SET_PROFILE,
  data: profile
})

export const setToken = (token) => ({
  type: Action.SET_TOKEN,
  data: token
})

export const removeToken = () => ({
  type: Action.REMOVE_TOKEN
})

export const removeProfile = () => ({
  type: Action.REMOVE_PROFILE
})

export const setTicket = (ticket) => ({
  type: Action.SET_TICKET,
  data: ticket
})

export const setClinicId = (clinicId) => ({
  type: Action.SET_CLINIC_ID,
  data: clinicId
})

export const acceptCookies = () => ({
  type: Action.ACCEPT_COOKIES
})