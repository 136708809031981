import React from 'react';
import ReactDOM from 'react-dom';
import './static/scss/index.scss';
import {App} from './components/';
import reportWebVitals from './reportWebVitals';
import {
    HashRouter, BrowserRouter
} from "react-router-dom";

import homeInitModule from './static/js/home';
import HomeHeaderProfile from "./components/HomeHeaderProfile/HomeHeaderProfile";
import store from "./store/store";
import {Provider} from "react-redux";
import CabinetHeaderProfile from "./components/CabinetHeaderProfile/CabinetHeaderProfile";
import ProfileModals from "./components/ProfileModals/ProfileModals";

import ApiService from "./services/ApiService";

import Navigation from "./components/Navigation/Navigation";
import {setClinicId} from "./store/actions/personalActions";
import QuestionnaireList from "./components/QuestionnaireList/QuestionnaireList";
import CookiesPanel from "./components/CookiesPanel/CookiesPanel";

homeInitModule();

ApiService.init();

let rootContainer = document.getElementById('root');

if (rootContainer) {
    ReactDOM.render(
      <App/>,
      rootContainer
    );

  let params = (new URL(document.location)).searchParams;
  let clinicId = params.get("clinicId");

  store?.dispatch(setClinicId(clinicId));
}

let rzdCabinetRootContainer = document.getElementById('rzd-cabinet-root');

if (rzdCabinetRootContainer) {
  const rzdClinic = process?.env.REACT_APP_RZD_CLINIC;

  ReactDOM.render(
    <App isIsolatedCabinet={true} defaultClinicId={rzdClinic} isolatedCabinetRootPath={"/rzd"}/>,
    rzdCabinetRootContainer
  );
}

let isolatedCabinetRootContainer = document.getElementById('isolated-cabinet-root');

if (isolatedCabinetRootContainer) {
  ReactDOM.render(
    <App isIsolatedCabinet={true}/>,
    isolatedCabinetRootContainer
  );
}

let cabinetHeaderProfileContainer = document.getElementById('cabinet-header-profile');

if (cabinetHeaderProfileContainer) {
  ReactDOM.render(
    <HashRouter>
      <Provider store={store}>
        <CabinetHeaderProfile />
        <ProfileModals />
        <CookiesPanel />
      </Provider>
    </HashRouter>,
    cabinetHeaderProfileContainer
  );
}

let headerProfileContainer = document.getElementById('home-header-profile');

if (headerProfileContainer) {
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <HomeHeaderProfile />
        <ProfileModals link={"/cabinet/"}/>
        <CookiesPanel />
      </Provider>
    </BrowserRouter>,
    headerProfileContainer
  );
}

let rootNavigation = document.getElementById('navigation');

if (rootNavigation) {
  ReactDOM.render(
    <HashRouter>
      <Provider store={store}>
        <Navigation />
      </Provider>
    </HashRouter>,
    rootNavigation
  );
}
let questionnaireList = document.getElementById('questionnaireList');

if (questionnaireList) {
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <QuestionnaireList />
      </Provider>
    </BrowserRouter>,
    questionnaireList
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
