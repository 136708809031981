import React from "react";
import {QuestionnaireType} from "./constants";

export const declOfNum = (number, words) => {
  let result = words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? number % 10 : 5]];

  return result ? result : words[0];
}

export const formatDateTime = (unix, withTime = true) => {
  let date = new Date(unix);

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let hours = date.getHours();
  let minutes = date.getMinutes();

  return `${day < 10 ? "0" + day : day}.${month < 10 ? "0" + month : month}.${year} ${withTime ? "| " + (hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) : ""}` ;
}

const gradient = [
  [
    -2,
    [68,221,27]
  ],
  [
    21,
    [145,222,33]
  ],
  [
    29,
    [242,211,30]
  ],
  [
    60,
    [235,81,26]
  ],
  [
    69,
    [234,58,34]
  ],
  [
    100,
    [230,39,32]
  ]
];

export const getColor = (point) => {
  let sliderWidth = 1160;
  let colorRange = []

  for (let i = 0; i < gradient.length; i++) {
    if (point <= gradient[i][0]) {
      colorRange = [i - 1, i]
      break;
    }
  }

  let firstColor = gradient[colorRange[0]]?.[1];
  let secondColor = gradient[colorRange[1]]?.[1];

  if (!firstColor || !secondColor)
    return '100,100,100';

  let firstColorX = sliderWidth * (gradient[colorRange[0]][0] / 100);
  let secondColorX = sliderWidth * (gradient[colorRange[1]][0] / 100) - firstColorX;
  let sliderX = sliderWidth * (point / 100) - firstColorX;
  let ratio = sliderX / secondColorX

  return pickHex(secondColor, firstColor, ratio);
}

function pickHex(color1, color2, weight) {
  let p = weight;
  let w = p * 2 - 1;
  let w1 = (w/1+1) / 2;
  let w2 = 1 - w1;
  let rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2)];
  return rgb;
}

export const getColorResult = (result, score) => {
  let colors = [];

  switch(result.length) {
    case 2:
      colors = ["#7AD669", "#FF6352"];
      break;

    case 3:
      colors = ["#FF9D66", "#56D433", "#FF6C6C"]
      break;

    case 4:
      colors = ["#7AD669", "#FFCA43", "#FFA25A", "#FF6352"];
      break;

    case 5:
      colors = ["#7AD669", "#FFCA43", "#FFA25A", "#FF6352", "#ca3636"];
      break;

    default:
      colors = ["#7AD669", "#FFCA43", "#FFA25A", "#FF6352", "#ca3636"];
      break;

  }

  let index = 0;

  result.forEach((item, i) => {
    if ((score >= item.from || item.from === null) && (score <= item.to || item.to === null)) {
      index = i;
    }
  })

  return colors[index];
}

export const getPhraseForDifferentBetweenIndividualRiskAndAgeGroupRisk = (individualRisk, ageGroupRisk) => {
  let individualRiskAndAgeGroupRiskDifferent = individualRisk - ageGroupRisk;

  let phrase = '';

  if (individualRiskAndAgeGroupRiskDifferent === 0) {
    phrase = <span><b>равен</b> риску в Вашей возрастной группе</span>;
  } else if(individualRiskAndAgeGroupRiskDifferent > 0) {
    phrase = <span><b>на {Math.abs(individualRiskAndAgeGroupRiskDifferent)}% больше</b>, чем риск в Вашей возрастной группе</span>;
  } else {
    phrase = <span><b>на {Math.abs(individualRiskAndAgeGroupRiskDifferent)}% меньше</b>, чем риск в Вашей возрастной группе</span>;
  }

  return phrase;
}

export const getIndividualRiskPhrase = (individualRisk) => {
  let phrase = '';

  if (individualRisk <= 25) {
    phrase = "низкий";
  } else if (individualRisk <= 50) {
    phrase = "средний";
  } else if (individualRisk <= 75) {
    phrase = "высокий";
  } else {
    phrase = "очень высокий";
  }

  return phrase;
}

export const getIconByQuestionnaireCode = (questionnaireCode) => {
  let icon = "";

  switch(questionnaireCode) {
    case QuestionnaireType.HOBL:
    case QuestionnaireType.ASTHMA:
    case QuestionnaireType.SEVERITY_OF_HOBL:
      icon = "lungs";
      break;
    case QuestionnaireType.GASTROESOPHAGEAL_REFLUX:
      icon = "reflux";
      break;
    case QuestionnaireType.DIABETES:
      icon = "diabetes";
      break;
    case QuestionnaireType.DEPRESSION:
    case QuestionnaireType.DEPRESSION_ANXIENTY:
    case QuestionnaireType.DEPRESSION_EFFECTIVENESS_OF_ANTIDEPRESSANTS:
      icon = "depression";
      break;
    default:
      icon = "heart-2";
      break;
  }

  return icon;
}

export const getAffiliationQuestionnaire = (questionnaireCode) => {
  let affiliation = {
    isDepression: false,
    isCardiovascularDiseases: false,
    isAnotherQuestionnaire: false
  };

  switch(questionnaireCode) {
    case QuestionnaireType.CARDIOVASCULAR_DISEASES:
      affiliation.isCardiovascularDiseases = true;

      break;

    case QuestionnaireType.DEPRESSION:
    case QuestionnaireType.DEPRESSION_ANXIENTY:
    case QuestionnaireType.DEPRESSION_EFFECTIVENESS_OF_ANTIDEPRESSANTS:
      affiliation.isDepression = true;

      break;

    default:
      affiliation.isAnotherQuestionnaire = true;

      break;
  }

  return affiliation;
}

