import React from 'react';
import QuestionnaireList from "../QuestionnaireList/QuestionnaireList";

export const QuestionnaireListPage = (props) => (
  <div className="main-container__inner">
    <div className="container">
      <h1 className="main-container__header main-container__header--no-padding-bottom heading">Пройдите обследование</h1>

      <div className="main-container__description">
        <p>Пройдя самостоятельное обследование на нашем портале, Вы сможете получить первичную картину
          здоровья по ряду заболеваний и факторов риска.  «Эксперт Скрининг»  содержит уникальную
          технологию оценки и управления индивидуальным риском сердечно-сосудистых заболеваний,
          прошедшую клиническую апробацию, персональные алгоритмы для принятия решений по коррекции образа жизни,
          которые можно обсудить с Вашим врачом. Управляйте своим здоровьем!</p>
      </div>

      <QuestionnaireList {...props}/>

    </div>

  </div>
)
