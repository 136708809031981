import {questionnaire as Action} from "../constants"

export const setQuestionnaireList = (questionnaireList) => ({
  type: Action.SET_QUESTIONNAIRE_LIST,
  data: questionnaireList
})

export const setQuestionnaire = (questionnaire) => ({
  type: Action.SET_QUESTIONNAIRE,
  data: questionnaire
})

export const toNextModule = () => ({
  type: Action.TO_NEXT_MODULE
})

export const toPrevModule = () => ({
  type: Action.TO_PREV_MODULE
})

export const setValue = (id, value) => ({
  type: Action.SET_VALUE,
  data: {id, value}
})

export const resetValues = (questionnaireCode) => ({
  type: Action.RESET_VALUES,
  data: questionnaireCode
})

export const setQuestionnaireResearchList = (questionnaireResearchList) => ({
  type: Action.SET_QUESTIONNAIRE_RESEARCH_LIST,
  data: questionnaireResearchList
})

export const setQuestionnaireResearch = (questionnaireResearch) => ({
  type: Action.SET_QUESTIONNAIRE_RESEARCH,
  data: questionnaireResearch
})

export const setLastQuestionnaireResearch = (lastQuestionnaireResearch) => ({
  type: Action.SET_LAST_QUESTIONNAIRE_RESEARCH,
  data: lastQuestionnaireResearch
})

export const removeQuestionnaireResearchList = () => ({
  type: Action.REMOVE_QUESTIONNAIRE_RESEARCH_LIST
})

export const removeQuestionnaireResearch = () => ({
  type: Action.REMOVE_QUESTIONNAIRE_RESEARCH
})

export const removeLastQuestionnaireResearch = () => ({
  type: Action.REMOVE_LAST_QUESTIONNAIRE_RESEARCH
})