import {personal as Action} from '../constants';

export const personalReducer = (state = {}, action) => {
  switch (action.type) {
    case Action.SET_IS_REFRESH_NEEDED: {
      return {...state, isRefreshNeeded: action.data};
    }
    case Action.SET_TOKEN: {
      return {...state, token: action.data};
    }
    case Action.REMOVE_TOKEN: {
      return {...state, token: null};
    }
    case Action.SET_PROFILE: {
      return {...state, profile: action.data};
    }
    case Action.REMOVE_PROFILE: {
      return {...state, profile: null, ticket: null};
    }
    case Action.SET_TICKET: {
      return {...state, ticket: action.data}
    }
    case Action.SET_CLINIC_ID: {
      return {...state, clinicId: action.data}
    }
    case Action.ACCEPT_COOKIES: {
      return {...state, isCookiesAccepted: true}
    }
    default:
      return state;
  }
}