import React from 'react';
import {declOfNum} from "../../utils/renderHelpers";

export const PointedResultScale = ({results, score}) => (
  <div className="result-section__scale scale-2">
    {results?.map(item => {
      let isChosen = (score >= item.from || item.from === null) && (score <= item.to || item.to === null);

      let width = 0;

      if (isChosen && item.to) {
        let from = item.from ? item.from : 0;

        width = Math.round((score - from ) / (item.to - from ) * 100);
      }

      let phrase = "";

      if (item.to === null) {
        phrase = `До ${item.from} ${declOfNum(item.from, ["балла", "баллов", "баллов"])}`;
      }

      if (item.from === null) {
        phrase = `От ${item.to} ${declOfNum(item.to, ["балла", "баллов", "баллов"])}`;
      }

      if (item.from !== null && item.to !== null) {
        phrase = `${item.from}-${item.to} ${declOfNum(item.to, ["балла", "баллов", "баллов"])}`;
      }

      return (
        <div key={item.name} className={"scale-2__item scale-2-item" + (isChosen? " scale-2-item--selected" : "")}>
          <div className="scale-2-item__wrapper">
            {isChosen && (
              <div>
                <div className="scale-2-item__result">
                  Ваш результат — {score} {declOfNum(score, ["балл", "балла", "баллов"])}
                </div>

                <div className="scale-2-item__bg">
                  <div className="scale-2-item__line" style={{"width": width + "%"}}/>
                </div>
              </div>
            )}

            <div className="scale-2-item__title">
              {item.name} <span className="hidden-xl">({phrase})</span>
            </div>
          </div>

          <div className="scale-2-item__points">{phrase}</div>
        </div>
      )
    })}
  </div>
)