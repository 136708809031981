import {profileModal as Action} from "../constants";

export const profileModalReducer = (state = {}, action) => {
  switch (action.type) {
    case Action.ADD_FORM_ERROR: {
      let {field, message} = action.data;

      let formErrors = Object.assign({}, state.formErrors);

      formErrors[field] = message;

      return {...state, formErrors: formErrors}
    }
    case Action.REMOVE_ALL_FORM_ERRORS: {
      return {...state, formErrors: new Map()}
    }
    case Action.SET_IDENTITY_TYPE: {
      return {...state, identityType: action.data}
    }
    case Action.SET_IS_SHOWN: {
      return {...state, isShown: action.data}
    }
    case Action.SET_MODAL_TYPE: {
      return {...state, modalType: action.data}
    }
    case Action.SET_OPTIONS: {
      return {...state, ...action.data}
    }
    default:
      return state;
  }
}