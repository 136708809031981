import React from 'react';

export const ObjectiveData = ({result}) => (
  <div className="influence-of-risk__subsection">
    <h2
      className="influence-of-risk__heading influence-of-risk__heading--mini-padding-top result-section__heading">Объективные
      данные</h2>
    <div className="influence-of-risk__table-wrapper">
    <table className="influence-of-risk__table table table--scrolled">
      <tbody>
      <tr>
        <th>Показатель</th>
        <th>Ваш уровень сейчас</th>
        <th>Рекомендуемый уровень</th>
      </tr>

      {result?.objectiveData.map(item => (
        <tr key={item.name}>
          <td>{item.name}</td>
          <td>
            {item.bad ? (
              <span className="table__table-data--not-normal">{item.currentValue}</span>
            ) : (
              <span>{item.currentValue}</span>
            )}
          </td>
          <td>{item.normalValue}</td>
        </tr>
      ))}
      </tbody>
    </table>
    </div>

    <div className="table__notice-wrapper">
      <p className="table__notice table__notice--not-normal">Отклонение от нормы</p>
      <p className="table__notice table__notice--normal">Норма</p>
    </div>

  </div>
)