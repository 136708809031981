import React from 'react';
import {Link, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import {
  fetchQuestionnaireListData,fetchQuestionnaireResultData
} from "../../store/actionCreators/questionnaireActionCreator";
import {connect} from "react-redux";
import Dropdown from "../Dropdown/Dropdown";
import {
  declOfNum,
  formatDateTime, getAffiliationQuestionnaire,
  getIconByQuestionnaireCode
} from "../../utils/renderHelpers";
import {IdentityType, ModalType, QuestionnaireType} from "../../utils/constants";
import {PointedQuestionnaireResult} from "../PointedQuestionnaireResult/PointedQuestionnaireResult";
import {setOptions} from "../../store/actions/profileModalActions";
import {RiskScale} from "../RiskScale/RiskScale";
import {ObjectiveData} from "../ObjectiveData/ObjectiveData";
import {InfluenceOfFactors} from "../InfluenceOfFactors/InfluenceOfFactors";

class ResultPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isTreatment: false,
    }
  }

  setIsTreatment = (isTreatment) => {
    this.setState({
      isTreatment: isTreatment
    })
  }

  componentDidMount() {
    let { id } = this.props.match?.params;

    if (id) {
      this.props.fetchQuestionnaireResearch(id);
    }
  }

  render() {
    let result = this.props.questionnaireResearch ?? [];
    const targetForDepressQuestionnaire = this.props.targetForDepressQuestionnaire || '_blank';
    const baseUrl = this.props.baseUrl || '';
    const hideRegistrationButton = !!this.props.hideRegistrationButton;
    const showDownloadLink = !!this.props.showDownloadLink;

    let icon = getIconByQuestionnaireCode(result[0]?.code);
    let {isDepression, isCardiovascularDiseases, isAnotherQuestionnaire} = getAffiliationQuestionnaire(result[0]?.code);

    const {isTreatment} = this.state;

    return (
      <div className="main-container__inner main-result">

        <div className="container">
          <div className="main-result__header">

            <div className="main-result__main-conclusion main-conclusion">
              <div className={"main-conclusion__wrapper icon-" + icon}>
                {isCardiovascularDiseases && (
                  <div>
                    <h1 className="main-container__header main-conclusion__heading heading">Заключение</h1>
                    <p className="main-conclusion__text">Риск сердечно-сосудистых заболеваний</p>
                  </div>
                )}

                {isDepression ? (
                  <h1
                    className="main-container__header main-conclusion__heading main-conclusion__heading--simple heading">Результаты
                    скрининга «Депрессия» </h1>
                ) : null}

                {isAnotherQuestionnaire ? (
                  <h1
                    className="main-container__header main-conclusion__heading main-conclusion__heading--simple heading">Результаты
                    скрининга «{result[0]?.name}» </h1>
                ) : null}

                <p className="main-conclusion__description">
                  Ниже Вы можете ознакомиться с детальными результатами обследования, а также получить их на почту или
                  скачать файлом. {isCardiovascularDiseases && (<span>Внизу Вы получите рекомендации по улучшению состояния своего здоровья если таковые необходимы.</span>)}
                </p>

                {!this.props.token && !hideRegistrationButton && (
                  <div className="main-conclusion__button-wrapper hidden-xs">
                    <button className="main-conclusion__button button button-primary button-primary--mini"
                            type="button" onClick={this.props.sendResearchOnEmail}>Отправить
                    </button>
                    <button className="main-conclusion__button button button-primary button-primary--mini"
                            type="button" onClick={this.props.downloadResearch}>Сохранить
                    </button>
                  </div>
                )}

                {hideRegistrationButton && showDownloadLink && (
                  <div className="main-conclusion__button-wrapper hidden-xs">
                    <a className="main-conclusion__button button button-primary button-primary--mini"
                            type="button" href={result[0]?.downloadLink} target={"_blank"}>Скачать PDF
                    </a>
                  </div>
                )}
              </div>
            </div>

            <div className="main-result__patient-info-card patient-info-card">
              <p className="patient-info-card__number">№ {result[0]?.ticket}</p>
              <p className="patient-info-card__description">
                Уникальный номер в системе
                (назовите его Вашему врачу)
              </p>

              <div className="patient-info-card__item">
                <p className="patient-info-card__item-title">Дата и время теста</p>
                <p className="patient-info-card__item-description">{formatDateTime(result[0]?.date)}</p>
              </div>

              {result[0]?.age && (
                <div className="patient-info-card__item">
                  <p className="patient-info-card__item-title">Пациент</p>
                  <p className="patient-info-card__item-description">{result[0]?.age} {declOfNum(result[0]?.age, ["года", "года", "лет"])}
                    {result[0]?.sex === "male" && (<b> М</b>)}
                    {result[0]?.sex === "female" && (<b> Ж</b>)}
                  </p>
                </div>
              )}

              {!this.props.token && !hideRegistrationButton && (
                <div className="main-conclusion__button-wrapper hidden-xl">
                  <button className="main-conclusion__button button button-primary button-primary--mini"
                          type="button" onClick={this.props.sendResearchOnEmail}>Отправить
                  </button>
                  <button className="main-conclusion__button button button-primary button-primary--mini"
                          type="button" onClick={this.props.downloadResearch}>Сохранить
                  </button>
                </div>
              )}

              {hideRegistrationButton && showDownloadLink && (
                <div className="main-conclusion__button-wrapper hidden-xl">
                  <a className="main-conclusion__button button button-primary button-primary--mini"
                     type="button" href={result[0]?.downloadLink} target={"_blank"}>Скачать PDF
                  </a>
                </div>
              )}
            </div>

          </div>

        </div>

        {isCardiovascularDiseases && (
          <RiskScale result={result[0]} />
        )}

        {isCardiovascularDiseases && (
          <div className="main-result__influence-of-risk influence-of-risk result-section">
            <div className="container">
              <h2 className="influence-of-risk__heading result-section__heading">Степень влияния факторов риска на
                развитие сердечно-сосудистых заболеваний</h2>

              <InfluenceOfFactors result={result[0]}/>

              {result[0]?.individualFactors?.length ? (
                <div className="influence-of-risk__subsection">
                  {result[0]?.cerebralStroke && result[0]?.myocardialInfarction && (
                    <h2 className="influence-of-risk__heading result-section__heading">Помимо перенесенных инфаркта и инсульта
                      наибольшую значимость в Вашем суммарном индивидуальном сердечно-сосудистом риске имеют следующие факторы:</h2>
                  )}

                  {!result[0]?.cerebralStroke && result[0]?.myocardialInfarction && (
                    <h2 className="influence-of-risk__heading result-section__heading">Помимо перенесенного инфаркта
                      наибольшую значимость в Вашем суммарном индивидуальном сердечно-сосудистом риске имеют следующие факторы:</h2>
                  )}

                  {result[0]?.cerebralStroke && !result[0]?.myocardialInfarction && (
                    <h2 className="influence-of-risk__heading result-section__heading">Помимо перенесенного инсульта
                      наибольшую значимость в Вашем суммарном индивидуальном сердечно-сосудистом риске имеют следующие факторы:</h2>
                  )}

                  {!result[0]?.cerebralStroke && !result[0]?.myocardialInfarction && (
                  <h2 className="influence-of-risk__heading result-section__heading">Наибольшую значимость в Вашем
                    суммарном индивидуальном сердечно-сосудистом риске имеют следующие факторы:</h2>
                  )}

                  <ol className="influence-of-risk__order-list">
                    {result[0]?.individualFactors.map(item => (
                      <li key={item} className="influence-of-risk__order-list-item">{item}</li>
                    ))}
                  </ol>

                  <p className="influence-of-risk__description">
                    Все эти факторы являются управляемыми
                  </p>
                </div>
              ) : null}

              {result[0]?.identifiedDiseases?.length ? (
                <div className="influence-of-risk__subsection">
                  <h2
                    className={"influence-of-risk__heading result-section__heading" + (result[0]?.individualFactors.length ? "influence-of-risk__heading--no-padding-top" : "")}>Также
                    по результатам обследования у Вас выявлено:</h2>

                  <ul className="influence-of-risk__unordered-list">
                    {result[0]?.identifiedDiseases.map(item => (
                      <li key={item} className="influence-of-risk__unordered-list-item">{item}</li>
                    ))}
                  </ul>

                </div>
              ) : null}

              {result[0]?.objectiveData?.length ? (
                <ObjectiveData result={result[0]} />
              ) : null}
            </div>
          </div>)}

        {isDepression && (
          <div className={"main-conclusion__tabs tabs" + (isTreatment ? " tabs--green" : "")}>
            <div className="tabs__container container">
              <input type="radio" id="tab1" name="tabs"
                     defaultChecked={result[0]?.code === QuestionnaireType.DEPRESSION_ANXIENTY}
                     onChange={(e) => this.setIsTreatment(false)}/>
              <input type="radio" id="tab2" name="tabs"
                     defaultChecked={result[0]?.code === QuestionnaireType.DEPRESSION}
                     onChange={(e) => this.setIsTreatment(false)}/>
              <input type="radio" id="tab3" name="tabs"
                     defaultChecked={result[0]?.code === QuestionnaireType.DEPRESSION_EFFECTIVENESS_OF_ANTIDEPRESSANTS}
                     onChange={(e) => this.setIsTreatment(false)}/>
              <input type="radio" id="tab4" name="tabs"
                     onChange={(e) => this.setIsTreatment(e.target.checked)}/>

              <ul className="tabs__row row">
                <li className="col">
                  <label className="tabs__tab" htmlFor="tab1">Тревожность</label>
                </li>

                <li className="col">
                  <label className="tabs__tab" htmlFor="tab2">Депрессия</label>
                </li>

                <li className="col">
                  <label className="tabs__tab" htmlFor="tab3">Оценка эффективности антидепресантов</label>
                </li>

                <li className="col">
                  <label className="tabs__tab tabs__tab--green" htmlFor="tab4">Лечение</label>
                </li>
              </ul>

              <div className="tabs__content">
                {result[0]?.code === QuestionnaireType.DEPRESSION_ANXIENTY ? (
                  <PointedQuestionnaireResult results={result}/>
                ) : (
                  <div className="result-section">
                    <h2 className="tabs__heading result-section__heading">Обследование не пройдено</h2>

                    <Link className="questionnaire-card__button button button-primary button-primary--mini"
                          target={targetForDepressQuestionnaire}
                          to={baseUrl + "/questionnaire/" + this.props.questionnaireList.find(item => item.code === QuestionnaireType.DEPRESSION_ANXIENTY)?.code}>Пройти
                      обследование</Link>
                  </div>
                )}

                {result?.[0]?.code && window.questionnaires?.[QuestionnaireType.DEPRESSION_ANXIENTY]?.resultDescription
                  ? (
                    <div className="influence-of-risk__subsection">

                      <div className="influence-of-risk__description influence-of-risk__description--padding-top"
                           dangerouslySetInnerHTML={{__html:window.questionnaires?.[QuestionnaireType.DEPRESSION_ANXIENTY]?.resultDescription }}>
                      </div>

                    </div>
                  ) : null}
              </div>

              <div className="tabs__content">
                {result[0].code === QuestionnaireType.DEPRESSION ? (
                  <PointedQuestionnaireResult results={result}/>
                ) : (
                  <div className="result-section">
                    <h2 className="tabs__heading result-section__heading">Обследование не пройдено</h2>

                    <Link className="questionnaire-card__button button button-primary button-primary--mini"
                          target={targetForDepressQuestionnaire}
                          to={baseUrl + "/questionnaire/" + this.props.questionnaireList.find(item => item.code === QuestionnaireType.DEPRESSION)?.code}>Пройти
                      обследование</Link>
                  </div>
                )}

                {result?.[0]?.code && window.questionnaires?.[QuestionnaireType.DEPRESSION]?.resultDescription
                  ? (
                    <div className="influence-of-risk__subsection">

                      <div className="influence-of-risk__description influence-of-risk__description--padding-top"
                           dangerouslySetInnerHTML={{__html:window.questionnaires?.[QuestionnaireType.DEPRESSION]?.resultDescription }}>
                      </div>

                    </div>
                  ) : null}
              </div>

              <div className="tabs__content">
                {result?.[0]?.code === QuestionnaireType.DEPRESSION_EFFECTIVENESS_OF_ANTIDEPRESSANTS ? (
                  <PointedQuestionnaireResult results={result}/>
                ) : (
                  <div className="result-section">
                    <h2 className="tabs__heading result-section__heading">Обследование не пройдено</h2>

                    <Link className="questionnaire-card__button button button-primary button-primary--mini"
                          target={targetForDepressQuestionnaire}
                          to={baseUrl + "/questionnaire/" + this.props.questionnaireList.find(item => item.code === QuestionnaireType.DEPRESSION_EFFECTIVENESS_OF_ANTIDEPRESSANTS)?.code}>Пройти
                      обследование</Link>
                  </div>
                )}

                {result?.[0]?.code && window.questionnaires?.[QuestionnaireType.DEPRESSION_EFFECTIVENESS_OF_ANTIDEPRESSANTS]?.resultDescription
                  ? (
                    <div className="influence-of-risk__subsection">

                      <div className="influence-of-risk__description influence-of-risk__description--padding-top"
                           dangerouslySetInnerHTML={{__html:window.questionnaires?.[QuestionnaireType.DEPRESSION_EFFECTIVENESS_OF_ANTIDEPRESSANTS]?.resultDescription }}>
                      </div>

                    </div>
                  ) : null}
              </div>

              <div className="tabs__content tabs__content--green">
                <div className="result-section">
                  <h2
                    className="tabs__heading result-section__heading result-section__heading--add-margin-top">Основными
                    видами лечения тревожности и депрессии являются: </h2>

                  <div className="media-objects">
                    <div className="media-objects__item">
                      <h3 className="media-objects__title icon-pills-2">
                        1) Антидепрессанты
                      </h3>
                    </div>

                    <div className="media-objects__item">
                      <h3 className="media-objects__title icon-psychotherapy">
                        2) Психотерапия.
                      </h3>
                    </div>

                    <div className="media-objects__item">
                      <h3 className="media-objects__title icon-ball">
                        3) Занятия спортом.
                      </h3>
                    </div>

                    <div className="media-objects__item">
                      <h3 className="media-objects__title icon-positive-man">
                        4) Комбинация антидепрессантов и психотерапия.
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="result-section">
                  <h2 className="tabs__heading result-section__heading result-section__heading--normal-padding-top">Что
                    делать: </h2>

                  <div className="result-section__description">
                    <p>Консультация со специалистом поможет Вам лучше понять ваше состояние, рационализировать ситуацию,
                      провести обследование, выбрать лечение, достичь результата.
                      При депрессии легкой степени, в 40% случаев, особенно у женщин, в течение 3-4 месяцев улучшение
                      состояния происходит самостоятельно. </p>

                    <p>Выбор лечения и эффективность результата всегда индивидуальны. Рекомендуем обсудить возникшие
                      вопросы с психотерапевтом или психологом.</p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        )}

        {isAnotherQuestionnaire && (
          <div className="recommendations">
            <div className="container">
              <PointedQuestionnaireResult results={result}/>
            </div>
          </div>
        )}

        <div
          className={"main-result__recommendations recommendations result-section" + (isTreatment ? " recommendations--green" : "")}>
          <div className="container">

            {this.props.appointmentWithADoctor ? (
              <div className='appointment-with-a-doctor'>
                <p className='appointment-with-a-doctor__title'>
                  По результатам обследования для Вас были сформированы персонализированные рекомендации по&nbsp;управлению
                  суммарным индивидуальным риском сердечно-сосудистых заболеваний.
                </p>

                <p className='appointment-with-a-doctor__offer appointment-with-a-doctor__offer--mobile'>Для обсуждения рекомендаций обратитесь к медицинскому специалисту.</p>

                <div className='appointment-with-a-doctor__flex-wrapper'>
                  <div className='appointment-with-a-doctor__left-column'>
                    <img className='appointment-with-a-doctor__image' src={'/bitrix/templates/screening/static/media/doctor.jpg'} alt={''}/>
                  </div>

                  <div className='appointment-with-a-doctor__right-column'>
                    <p className='appointment-with-a-doctor__offer appointment-with-a-doctor__offer--desktop'>Для обсуждения рекомендаций обратитесь к медицинскому специалисту.</p>
                    <p className='appointment-with-a-doctor__doctor-name'>Жигалова Марина<br /> Владимировна</p>
                    <p className='appointment-with-a-doctor__position'>Врач терапевт</p>

                    <div dangerouslySetInnerHTML={{__html: `
                     <a class="appointment-with-a-doctor__button button button-primary"
                       href="https://spectramed.ru/specialisty_catalog/zhigalova-marina-vladimirovna/" onclick="ym(85118782,'reachGoal','doctor_submit'); return true;" target="_blank">Записаться</a>
                    `}} />
                   </div>
                </div>
              </div>
        ) : (
          <>
            {result[0]?.recommendations?.length ? (
              <div>
                <h2 className="recommendations__heading result-section__heading">Рекомендации:</h2>

                <ul className="recommendations__list">
                  {result[0]?.recommendations.map(item => (
                    <li key={item.title} className="recommendations__item">
                      <Dropdown title={item.title}>
                        {item.description}
                      </Dropdown>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}

            {result[0]?.recommendations1?.length ? (
              <div className="influence-of-risk__subsection">
                <h2 className="influence-of-risk__heading result-section__heading">В связи с выявленными изменениями Вам
                  рекомендуется:</h2>

                <ol className="influence-of-risk__order-list">
                  {result[0]?.recommendations1.map(item => (
                    <li key={item} className="influence-of-risk__order-list-item">{item}</li>
                  ))}
                </ol>

              </div>
            ) : null}

            {result[0]?.recommendations2?.length ? (
              <div className="influence-of-risk__subsection">
                <h2 className="influence-of-risk__heading result-section__heading result-section__heading--no-padding-top">Для более точной оценки Вашего риска необходимо обязательно: </h2>

                <ol className="influence-of-risk__order-list">
                  {result[0]?.recommendations2.map(item => (
                    <li key={item} className="influence-of-risk__order-list-item">{item}</li>
                  ))}
                </ol>

              </div>
            ) : null}

            {result[0]?.recommendations3 ? (
              <div className="influence-of-risk__subsection">

                <p className="influence-of-risk__description influence-of-risk__description--padding-top">
                  {result[0]?.recommendations3}
                </p>

              </div>
            ) : null}

            {result?.[0]?.code && window.questionnaires?.[result[0]?.code]?.resultDescription &&
            result?.[0]?.code !== QuestionnaireType.DEPRESSION_EFFECTIVENESS_OF_ANTIDEPRESSANTS &&
            result?.[0]?.code !== QuestionnaireType.DEPRESSION &&
            result?.[0]?.code !== QuestionnaireType.DEPRESSION_ANXIENTY
              ? (
                <div className="influence-of-risk__subsection">

                  <div className="influence-of-risk__description influence-of-risk__description--padding-top" dangerouslySetInnerHTML={{__html:window.questionnaires?.[result[0]?.code]?.resultDescription }}>
                  </div>

                </div>
              ) : null}

            {result?.[0]?.code === QuestionnaireType.ASTHMA ? (
              <div className="influence-of-risk__subsection">

                <p className="influence-of-risk__description">Рекомендуем ознакомиться с диетическими особенностями питания при бронхиальной астме</p>

                <table className="influence-of-risk__table table table--normal">
                  <tbody>
                  <tr>
                    <td>Пищевые продукты и добавки</td>
                    <td>Сульфиты (консерванты, которые часто входят в состав лекарств и таких пищевых продуктов как
                      картофельные чипсы, креветки, сухофрукты, пиво и вино) нередко причастны к развитию тяжелых
                      обострений БА</td>
                    <td>В случае аллергии на продукт или добавку исключение этого продукта может привести к снижению
                      частоты обострений БА. </td>
                  </tr>

                  <tr>
                    <td>Ожирение</td>
                    <td>Исследования показывают взаимосвязь между увеличением массы тела и симптомами БА</td>
                    <td>Пациентам с избыточной массой тела рекомендуется снижение веса для улучшения состояния здоровья
                      и течения БА.</td>
                  </tr>
                  </tbody>
                </table>

              </div>
            ) : null}
          </>
        )}
          </div>

        </div>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  questionnaireResearch: state.questionnaire.questionnaireResearch,
  questionnaireList: state.questionnaire.questionnaireList,
  token: state.personal.token
})

const mapDispatchToProps = (dispatch) => ({
  fetchQuestionnaireResearch: (id) => bindActionCreators(() => (fetchQuestionnaireResultData(id)), dispatch)(),
  fetchQuestionnaireList: bindActionCreators(fetchQuestionnaireListData, dispatch),
  downloadResearch: () => {
    dispatch(setOptions({
      modalType: ModalType.REGISTRATION,
      registrationTitle: null,
      isShown: true,
      identityType: IdentityType.EMAIL,
      text: "Зарегистрироваться и сохранить файл с результатами обследования",
      isOnlyOneAction: true,
      isNotChooseIdentity: false,
      mainButtonText: "Зарегистрироваться и сохранить",
      additionalButton: null,
      isEmailSend: false,
      isDownloadResearch: true
    }))
  },
  sendResearchOnEmail: () => {
    dispatch(setOptions({
      modalType: ModalType.REGISTRATION,
      isShown: true,
      registrationTitle: "Отправить на почту",
      identityType: IdentityType.EMAIL,
      text: "Укажите Ваш email для получения результата",
      isOnlyOneAction: true,
      isNotChooseIdentity: true,
      mainButtonText: "Отправить",
      additionalButton: null,
      isEmailSend: true,
      isDownloadResearch: false
    }))
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ResultPage));
