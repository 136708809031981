import React from 'react';
import { Provider } from 'react-redux';
import store from '../../store/store';
import { QuestionnairePage, QuestionnaireListPage, SettingsPage, DepressionQuestionnaireList } from "../";
import {HashRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import NotExistsPage from "../NotExistsPage/NotExistsPage";
import PrivateRoute from "../PrivateRouter/PrivateRouter";
import MessageModal from "../MessageModal/MessageModal";
import ResultPage from "../ResultPage/ResultPage";
import Loading from "../Loading/Loading";
import Personal from "../Personal/Personal";
import HoblQuestionnaireList from "../HoblQuestionnaireList/HoblQuestionnaireList";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import {PrivacyPolicy} from "../PrivacyPolicy/PrivacyPolicy";
import {TermsOfUse} from "../TermsOfUse/TermsOfUse";
import {Faq} from "../Faq/Faq";
import {IsolatedCabinetWrapper} from "../IsolatedCabinetWrapper/IsolatedCabinetWrapper";

function App({isIsolatedCabinet = false, isolatedCabinetRootPath = "/clinic/:clinicId", defaultClinicId = null}) {
  return (
    <Provider store={store}>
      <Router>
          {isIsolatedCabinet ? (
            <Switch>
              <Route path={isolatedCabinetRootPath}>
                <IsolatedCabinetWrapper defaultClinicId={defaultClinicId} page={defaultClinicId ? 'rzd-cabinet' : 'isolated-cabinet'}/>
              </Route>
              <Route path="*">
                <NotExistsPage withHomeLink={false}/>
              </Route>
            </Switch>
          ) : (
            <Switch>
              <Route exact path="/">
                <Redirect to="/questionnaires"/>
              </Route>
              <Route path="/questionnaires">
                <ScrollToTop/>
                <QuestionnaireListPage/>
              </Route>
              <Route path="/depression-questionnaires">
                <ScrollToTop/>
                <DepressionQuestionnaireList/>
              </Route>
              <Route path="/hobl-questionnaires">
                <ScrollToTop/>
                <HoblQuestionnaireList/>
              </Route>
              <Route exact path="/questionnaire/appointment-with-a-doctor">
                <ScrollToTop/>
                <QuestionnairePage questionnaireCode={'cardiovascular_diseases'} appointmentWithADoctor={true}/>
              </Route>
              <Route path="/questionnaire/:questionnaireCode">
                <ScrollToTop/>
                <QuestionnairePage/>
              </Route>
              <Route path="/personal">
                <ScrollToTop/>
                <Personal/>
              </Route>
              <Route exact path="/screening-result/appointment-with-a-doctor">
                <ScrollToTop/>
                <ResultPage appointmentWithADoctor={true}/>
              </Route>
              <Route path="/screening-result/:id">
                <ScrollToTop/>
                <ResultPage/>
              </Route>
              <Route path="/screening-result">
                <ScrollToTop/>
                <ResultPage/>
              </Route>
              <Route path="/terms-of-use">
                <ScrollToTop/>
                <TermsOfUse/>
              </Route>
              <Route path="/privacy-policy">
                <ScrollToTop/>
                <PrivacyPolicy/>
              </Route>
              <Route path="/faq">
                <ScrollToTop/>
                <Faq/>
              </Route>
              <PrivateRoute path="/settings" component={SettingsPage}/>
              <Route path="*">
                <NotExistsPage />
              </Route>
            </Switch>
          )}
      </Router>
      <MessageModal />
      <Loading />
    </Provider>
  );
}

export default App;
