import React from "react";
import {connect} from 'react-redux';
import Modal from '@material-ui/core/Modal';
import ApiService from "../../services/ApiService";
import InputMask from "react-input-mask";
import Converter from "../../utils/converter";
import Validator from "../../utils/validator";
import {setToken, setProfile, removeToken, removeProfile} from "../../store/actions/personalActions";
import {
  setIsShown,
  setModalType,
  setIdentityType,
  addFormError,
  removeAllFormErrors, setOptions
} from "../../store/actions/profileModalActions";
import {IdentityType, ModalType} from "../../utils/constants";
import {showMessageModal} from "../../store/actions/messageModalActions";
import {Link, withRouter} from "react-router-dom";

class ProfileModals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      phone: '',
      password: '',
      verifyCode: '',
      newPassword: '',
      confirmPassword: '',

      isLoading: false
    };
  }

  handleEmailChange = (e) => {
    this.setState({
      email: e.target.value.trim()
    })
  }

  handlePhoneChange = (e) => {
    this.setState({
      phone: Converter.ToServerFormattedPhone(e.target.value)
    })
  }

  handlePasswordChange = (e) => {
    this.setState({
      password: e.target.value.trim()
    })
  }

  handleVerifyCodeChange = (e) => {
    this.setState({
      verifyCode: e.target.value.trim()
    })
  }

  handleNewPasswordChange = (e) => {
    this.setState({
      newPassword: e.target.value.trim()
    })
  }

  handleConfirmPasswordChange = (e) => {
    this.setState({
      confirmPassword: e.target.value.trim()
    })
  }

  onLogin = async () => {
    this.props.removeAllFormErrors();

    let isSuccess = true;
    let identity = this.getIdentity();
    let password = this.state.password;

    if (!Validator.identity(identity)) {
      this.props.addFormError("identity", "Поле Ваш телефон или Ваш email заполнено некорректно");
      isSuccess = false;
    }

    if (!Validator.password(password)) {
      this.props.addFormError("password", "Поле Ваш пароль заполнено некорректно");
      isSuccess = false;
    }

    if (isSuccess) {
      await ApiService.loadBaseResource();

      const getTokenLink = ApiService.baseResource.link("auth:get-token");

      if (!getTokenLink) {
        this.props.addFormError("common", "Действие недоступно");

        return;
      }

      try {
        const tokenResponse = await ApiService.client.create(getTokenLink.prop("href"), {identity, password});

        this.props.setToken(tokenResponse.prop("token"));
        this.props.closeModal();

      } catch (error) {
        this.props.addFormError("common", error.response.data.error);

        return;
      }

      await ApiService.loadBaseResource(true);

      const profileLink = ApiService.baseResource.link("profile:my");

      if (!profileLink) {
        this.props.addFormError("common", "Действие недоступно");

        return;
      }

      try {
        const profileResponse = await profileLink.fetch();

        this.props.setProfile(profileResponse.props);
        this.clearFields();

        if (this.props.link) {
          window.location = this.props.link;
        } else {
          window.location = "#/personal";
        }
      } catch(error) {

      }
    }
  }

  onRegistration = async () => {
    this.props.removeAllFormErrors();

    let isSuccess = true;
    let identity = this.getIdentity();

    if (!Validator.identity(identity)) {
      this.props.addFormError("identity", "Поле Ваш телефон или Ваш email заполнено некорректно");
      isSuccess = false;
    }

    if (isSuccess) {
      await ApiService.loadBaseResource();

      const link = ApiService.baseResource.link("auth:registration");

      if (!link) {
        this.props.addFormError("common", "Действие недоступно");

        return;
      }

      try {
        ApiService.responses["registration"] = await ApiService.client.create(
          link.uri.uriTemplates.fill({
            ticket: this.props.ticket,
            clinicId: this.props.clinicId,
            isEmailSend: this.props.profileModal?.isEmailSend,
            isDownloadResearch: this.props.profileModal?.isDownloadResearch
          }), {identity});

        this.props.setModalType(ModalType.VERIFY_REGISTRATION);
        this.clearFields();

      } catch (error) {
        this.props.addFormError("common", error?.response?.data?.error);
      }
    }
  }

  onRecoveryPassword = async () => {
    this.props.removeAllFormErrors();

    let isSuccess = true;
    let identity = this.getIdentity();

    if (!Validator.identity(identity)) {
      this.props.addFormError("identity", "Поле Ваш телефон или Ваш email заполнено некорректно");
      isSuccess = false;
    }

    if (isSuccess) {
      await ApiService.loadBaseResource();

      const link = ApiService.baseResource.link("auth:recovery-password");

      if (!link) {
        this.props.addFormError("common", "Действие недоступно");

        return;
      }

      try {
        ApiService.responses["recovery-password"] = await ApiService.client.create(
          link.uri.uri, {identity});

        this.props.setModalType(ModalType.RESET_PASSWORD)
        this.clearFields();

      } catch (error) {
        this.props.addFormError("common", error?.response?.data?.error);
      }
    }
  }

  onChangePassword = async () => {
    this.props.removeAllFormErrors();

    let isSuccess = true;
    let verifyCode = this.state.verifyCode;
    let newPassword = this.state.newPassword;
    let confirmPassword = this.state.confirmPassword;

    if (!Validator.verifyCode(verifyCode)) {
      this.props.addFormError("verifyCode", "Введите, пожалуйста, код подтверждения");
      isSuccess = false;
    }

    if (!Validator.password(newPassword)) {
      this.props.addFormError("newPassword", "Пароль некорректный");
      isSuccess = false;
    }

    if (!Validator.comparisonPasswords(newPassword, confirmPassword)) {
      this.props.addFormError("confirmPassword", "Пароли не совпадают");
      isSuccess = false;
    }

    if (isSuccess) {
      await ApiService.loadBaseResource();

      const link = ApiService.responses["recovery-password"].link("auth:reset-password");

      if (!link) {
        this.props.addFormError("common", "Действие недоступно");

        return;
      }

      try {
        await ApiService.client.create(
          link.uri.uriTemplates.fill({verificationCode: this.state.verifyCode}), {password: newPassword});

        this.props.closeModal();

        this.clearFields();

        this.props.showMessageModal("Пароль был успешно измененен");

      } catch (error) {
        this.props.addFormError("common", error?.response?.data?.error);
      }
    }
  }

  onVerifyRegistration = async () => {
    this.props.removeAllFormErrors();

    let isSuccess = true;
    let verifyCode = this.state.verifyCode;

    if (!Validator.verifyCode(verifyCode)) {
      this.props.addFormError("verifyCode", "Введите, пожалуйста, код подтверждения");
      isSuccess = false;
    }

    if (isSuccess) {
      await ApiService.loadBaseResource();

      const link = ApiService.responses["registration"].link("auth:verify-registration");

      if (!link) {
        this.props.addFormError("common", "Действие недоступно");

        return;
      }

      try {
        const response = await ApiService.client.create(
          link.uri.uriTemplates.fill({verificationCode: this.state.verifyCode}), {});

        this.props.setToken(response.prop("token"));

        this.clearFields();
        this.props.closeModal();
      } catch (error) {
        this.props.addFormError("common", error?.response?.data?.error);

        return;
      }

      await ApiService.loadBaseResource(true);

      const profileLink = ApiService.baseResource.link("profile:my");

      if (!profileLink) {
        this.props.addFormError("common", "Действие недоступно");

        return;
      }

      try {
        const profileResponse = await profileLink.fetch();

        this.props.setProfile(profileResponse.props);
        this.clearFields();

        if (this.props.profileModal?.identityType === IdentityType.EMAIL) {
          window?.ym(85118782,'reachGoal','registration_email')
        }

        if (this.props.profileModal?.identityType === IdentityType.PHONE) {
          window?.ym(85118782,'reachGoal','registration_phone')
        }

        if (this.props.link) {
          window.location.href = this.props.link;
        } else if(this.props.profileModal?.isDownloadResearch) {
          this.props.resetOptions();
          this.props.showMessageModal("Вы успешно зарегистрировались",
            "Вы можете скачать результаты обследования, кликнув по кнопке 'Скачать'", {
            title: "Скачать",
            href: this.props.downloadLink + "?access_token=" + this.props.token?.access_token
          })

        } else if(this.props.profileModal?.isEmailSend) {
          this.props.resetOptions();
          this.props.showMessageModal("Вы успешно зарегистрировались",
            "Результаты обследования были отправлены Вам на почту")
        } else {
          window?.history.pushState({}, '', '#' + (this.props.profileModal?.redirectLink || "/personal"))
          window.location = '#' + (this.props.profileModal?.redirectLink || "/personal");
        }

      } catch(error) {

      }
    }
  }

  getIdentity = () => {
    let identity = '';

    switch (this.props.profileModal?.identityType) {
      case IdentityType.EMAIL:
        identity = this.state.email;

        break;
      case IdentityType.PHONE:
        identity = this.state.phone;

        break;
      default:
    }

    return identity;
  }

  clearFields = () => {
    this.setState({
      email: '',
      phone: '',
      password: '',
      verifyCode: '',
      newPassword: '',
      confirmPassword: '',
    })
  }

  render() {
    let header = null;
    let footer = null;

    let {formErrors, identityType, modalType, isShown, text, isOnlyOneAction, isNotChooseIdentity, mainButtonText, additionalButton, registrationTitle} = this.props.profileModal;
    let {closeModal, setIdentityType, setModalType} = this.props;

    switch (modalType) {
      case ModalType.LOGIN:
        header = <div className="modal__header-tabs">
          <h2 className="modal__header" id="modal-title">Войти в личный кабинет</h2>
          {!isOnlyOneAction && (
          <button className="modal__header-button button button-link"
                  onClick={() => {setModalType(ModalType.REGISTRATION)}}>Регистрация</button>
          )}
        </div>;

        footer = <div className="form__footer form__footer--for-mobile-fix">
          <button className="button button-primary button-primary--mini" type="button"
                  onClick={this.onLogin}>{mainButtonText ? mainButtonText : "Войти в кабинет"}</button>
          <button className="button button-link button-link--mini" type="button"
                  onClick={() => {setModalType(ModalType.PASSWORD_RECOVERY)}}>Забыли пароль?</button>
        </div>;

        break;

      case ModalType.REGISTRATION:
        header = <div className="modal__header-tabs">
          <h2 className="modal__header" id="modal-title">{registrationTitle ? registrationTitle : "Регистрация"}</h2>
          {!isOnlyOneAction && (
          <button className="modal__header-button button button-link"
                  onClick={() => {setModalType(ModalType.LOGIN)}}>Войти в личный кабинет</button>
          )}
        </div>;

        footer = <div>
          <div className="form__footer form__footer--center">
            <button className="modal__profile-button button button-primary button-primary--mini" type="button"
                    onClick={this.onRegistration}>{mainButtonText ? mainButtonText : "Зарегистрироваться"}</button>
          </div>
            {additionalButton ? (
              <div className="modal__additional-buttons-wrapper">
                <Link className="button button-link button-link--mini" to={additionalButton.to}
                      onClick={() => {
                        additionalButton?.onClick?.();
                        closeModal();
                      }}>{additionalButton.text}</Link>
              </div>
            ) : null}
          </div>
        ;

        break;

      case ModalType.PASSWORD_RECOVERY:
        header = <div className="modal__header-tabs">
          <h2 className="modal__header" id="modal-title">Восстановление пароля</h2>
          <button className="modal__header-button button button-link"
                  onClick={() => {setModalType(ModalType.LOGIN)}}>Назад</button>
        </div>;

        footer = <div className="form__footer form__footer--center">
          <button className="modal__profile-button button button-primary button-primary--mini" type="button"
                  onClick={this.onRecoveryPassword}>Отправить</button>
        </div>;

        break;

      case ModalType.RESET_PASSWORD:
        header = <div className="modal__header-tabs">
          <h2 className="modal__header" id="modal-title">Изменение пароля</h2>
          <button className="modal__header-button button button-link"
                  onClick={() => {setModalType(ModalType.PASSWORD_RECOVERY)}}>Назад</button>
        </div>;

        footer = <div className="form__footer form__footer--center">
          <button className="modal__profile-button button button-primary button-primary--mini"
                  type="button" onClick={this.onChangePassword}>Подтвердить</button>
        </div>;

        break;

      case ModalType.VERIFY_REGISTRATION:
        header = <div className="modal__header-tabs">
          <h2 className="modal__header" id="modal-title">Подтверждение регистрации</h2>
          <button className="modal__header-button button button-link"
                  onClick={() => {setModalType(ModalType.REGISTRATION)}}>Назад</button>
        </div>;

        footer = <div className="form__footer form__footer--center">
          <button className="modal__profile-button button button-primary button-primary--mini" type="button"
                  onClick={this.onVerifyRegistration}>Подтвердить</button>
        </div>;

        break;

      default:
    }

    return (
      <Modal
        className="modal"
        open={isShown}
        aria-labelledby="modal-title"
      >
        <div className="modal__content">
          <div className="modal__inner">

            <button className="modal__close" onClick={closeModal}></button>

            {header}

            {text && (modalType === ModalType.LOGIN || modalType === ModalType.REGISTRATION) && (
              <div className="modal__text modal__text--normal">
                {text}
              </div>
            )}

            <form className="modal__form form">

              {formErrors && Object.keys(formErrors).length > 0 ?
                (
                  <p className="form__messages">
                    {Object.values(formErrors).map(item => (
                      <span key={item}>{item}<br/></span>
                    ))}
                  </p>
                )
                : null}

              {modalType && !isNotChooseIdentity && modalType !== ModalType.RESET_PASSWORD && modalType !== ModalType.VERIFY_REGISTRATION &&
              <div className="form__control switcher">
                <button className={"switcher__button" + (identityType === IdentityType.EMAIL ? " switcher__button--active" : "")}
                        type="button" onClick={() => {setIdentityType(IdentityType.EMAIL)}}>email</button>
                <button className={"switcher__button" + (identityType === IdentityType.PHONE ? " switcher__button--active" : "")}
                        type="button" onClick={() => {setIdentityType(IdentityType.PHONE)}}>телефон</button>
              </div>
              }

              {identityType === IdentityType.EMAIL && modalType !== ModalType.VERIFY_REGISTRATION
              && modalType !== ModalType.RESET_PASSWORD &&
              <div className="form__control">
                <input className={"input" + (formErrors?.identity ? " input--invalid" : "")}
                       name="email" placeholder="Ваш email" value={this.state.email}
                       onChange={this.handleEmailChange}/>
              </div>
              }

              {identityType === IdentityType.PHONE && modalType !== ModalType.VERIFY_REGISTRATION
              && modalType !== ModalType.RESET_PASSWORD &&
              <div className="form__control">
                <InputMask mask="+7(999)999-99-99" maskChar="_"
                           className={"input" + (formErrors?.identity ? " input--invalid" : "")}
                           name="phone" placeholder="Ваш телефон" value={this.state.phone}
                           onChange={this.handlePhoneChange}/>
              </div>
              }

              {modalType === ModalType.LOGIN &&
              <div className="form__control">
                <input className={"input" + (formErrors?.password ? " input--invalid" : "")}
                       type="password"
                       name="password" placeholder="Ваш пароль" value={this.state.password}
                       onChange={this.handlePasswordChange}/>
              </div>
              }

              {(modalType === ModalType.VERIFY_REGISTRATION || modalType === ModalType.RESET_PASSWORD) &&
              <div className="form__control">
                <input className={"input" + (formErrors?.verifyCode ? " input--invalid" : "")}
                       name="verifyCode" placeholder="Код подтверждения" value={this.state.verifyCode}
                       onChange={this.handleVerifyCodeChange}/>
              </div>
              }

              {modalType === ModalType.RESET_PASSWORD &&
              <div className="form__control">
                <input className={"input" + (formErrors?.newPassword ? " input--invalid" : "")}
                       type="password"
                       name="newPassword" placeholder="Новый пароль" value={this.state.newPassword}
                       onChange={this.handleNewPasswordChange}/>
              </div>
              }

              {modalType === ModalType.RESET_PASSWORD &&
              <div className="form__control">
                <input className={"input" + (formErrors?.confirmPassword ? " input--invalid" : "")}
                       type="password"
                       name="confirmPassword" placeholder="Повторите пароль" value={this.state.confirmPassword}
                       onChange={this.handleConfirmPasswordChange}/>
              </div>
              }

              {footer}

            </form>
          </div>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  ticket: state.personal?.ticket,
  clinicId: state.personal?.clinicId,
  profile: state.personal?.profile,
  token: state.personal?.token,
  profileModal: state.profileModal,
  downloadLink: state.questionnaire?.questionnaireResearch?.[0].downloadLink
})

const mapDispatchToProps = dispatch => ({
  setToken(token) {
    dispatch(setToken(token));
  },
  setProfile(profile) {
    dispatch(setProfile(profile));
  },
  closeModal() {
    dispatch(setIsShown(false));
    dispatch(removeAllFormErrors());
  },
  setIdentityType(identityType) {
    dispatch(setIdentityType(identityType))
  },
  setModalType(modalType) {
    dispatch(setModalType(modalType))
  },
  addFormError(field, message) {
    dispatch(addFormError(field, message))
  },
  removeAllFormErrors() {
    dispatch(removeAllFormErrors());
  },
  onError() {
    dispatch(removeToken());
    dispatch(removeProfile());
  },
  showMessageModal(title, text, link) {
    dispatch(showMessageModal({title, text, link}))
  },
  resetOptions() {
    dispatch(setOptions({
      registrationTitle: null,
      isEmailSend: false,
      isDownloadResearch: false
    }));
  }
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileModals));